/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  marginr: {
    marginRight: 4,
  },
}));
const QualityManagements = (props) => {
  const dispatch = useDispatch();

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  const facilitycode = u.facilitycode;

  const [rows, setRows] = useState([]);

  const [reloadData, setreloadData] = useState(true);

  useEffect(() => {
    if (reloadData) {
      fetchData();
      setreloadData(false);
    }
  }, [reloadData]);
  const classes = useStyles();

  const handleNew = () => {
    props.history.push({
      pathname: "/qualitymanagement",
      state: { Id: "" },
    });
  };

  const columns = [
    {
      name: "Id",
      label: "ID",
      options: {
        display: "false",
        filter: false,
      },
    },
    {
      name: "QualityManagementDesc",
      display: true,
      label: "Quality Management",
      options: {
        sort: true,
      },
    },
    {
      name: "PhilHealthAccreditation",
      display: true,
      label: "PhilHealth Accreditation",
    },
    {
      name: "IsoCertifyingBody",
      display: true,
      label: "Iso CertifyingBody",
    },
    {
      name: "Description",
      display: true,
      label: "Description",
    },
    {
      name: "ValidityFrom",
      display: true,
      label: "Validity From",
      options: {
        sort: false,
      },
    },
    {
      name: "ValidityTo",
      display: true,
      label: "Validity To",
      options: {
        sort: false,
      },
    },
    {
      name: "AddedBy",
      display: true,
      label: "Added By",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "DateAdded",
      display: true,
      label: "Date Added",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "Updated By",
      display: true,
      label: "Updated By",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "DateUpdated",
      display: true,
      label: "Date Updated",
      options: {
        sort: false,
        display: false,
      },
    },
  ];

  const fetchData = () => {
    dispatch(setloading());

    axios
      .get(serverUrl + "/api/getqualitymanagementperfacility", {
        params: {
          facilitycode: facilitycode,
        },
      })
      .then((res) => {
        setRows(res.data.lst);
        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  const onTableChange = (action, tableState) => {
    console.log(action);
  };

  const onColumnSortChange = (changedColumn, direction) => {
    console.log(changedColumn);
    console.log(direction);
  };
  // customToolbarSelect: () => {},

  const customToolbarSelect = (selectedRows, displayData, setSelectedRows) => {
    const newSelectedRow = selectedRows.data[selectedRows.data.length - 1];
    const selectedData = displayData[newSelectedRow.index];
    const Id = selectedData.data[0];

    const handleEdit = () => {
      props.history.push({
        pathname: "/qualitymanagement",
        state: { Id: Id },
      });
    };

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.margin}
          onClick={handleEdit}
        >
          <FontAwesomeIcon icon={faEdit} className={classes.marginr} />
          Edit
        </Button>
      </div>
    );
  };

  const options = {
    filterType: "dropdown",
    selectableRows: "single",
    selectableRowsOnClick: true,
    serverSide: true,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
      customToolbarSelect(selectedRows, displayData, setSelectedRows),
    responsive: "scrollMaxHeight",
    onTableChange: (action, tableState) => onTableChange(action, tableState),
    onColumnSortChange: (changedColumn, direction) =>
      onColumnSortChange(changedColumn, direction),
    fixedHeader: true,
  };

  return (
    <div style={{ marginTop: 15 }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.margin}
        onClick={handleNew}
      >
        <FontAwesomeIcon icon={faPlus} className={classes.marginr} />
        New
      </Button>

      <MUIDataTable
        title={"Quality Management"}
        data={rows}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default withRouter(QualityManagements);

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { setloading, notloading } from "../../actions";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));
const SubmitReport = (props) => {
  const dispatch = useDispatch();
  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));
  const [showLog, setShowLog] = useState(false);
  const facilityname = s.facilityName;
  const [FacilityCode] = useState(props.FacilityCode);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [log, setLog] = useState([]);
  const classes = useStyles();

  const options = {
    download: false,
    filterType: "dropdown",
    isRowSelectable: false,
    search: false,
    viewColumns: false,
    filter: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    serverSide: false,
    print: false,
    pagination: false,
    page: 0,
    customToolbarSelect: () => {},
    responsive: "scrollMaxHeight",
    rowsPerPage: 20,
    rowsPerPageOptions: [10, 20, 100],
    count: log.length,
    expandableRows: false,
  };
  const columns = [
    {
      name: "Username",
      display: true,
      label: "Username",
      options: {
        display: "true",
        filter: false,
        sort: false,
      },
    },
    {
      name: "DateTimeStartStr",
      display: true,
      label: "Date Time Start",
      options: {
        display: "true",
        filter: false,
        sort: false,
      },
    },
    {
      name: "DateTimeEndStr",
      display: true,
      label: "Date Time End",
      options: {
        display: "true",
        filter: false,
        sort: false,
      },
    },
    {
      name: "Remarks",
      display: true,
      label: "Remarks",
      options: {
        display: "true",
        filter: false,
        sort: false,
      },
    },
  ];

  const handleSubmit = (endPoint, m) => {
    const t = `Submit ${m} now?`;
    Swal.fire({
      title: t,
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        try {
          var serverUrl = localStorage.getItem("serverUrl");
          var token = localStorage.getItem("token_id");
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          dispatch(setloading());
          setIsSubmitting(true);
          axios
            .post(serverUrl + "api/submitreport", {
              FacilityCode: FacilityCode,
              ReportingYear: props.ReportingYear,
              Username: u.username,
              Code: endPoint,
            })
            .then((res) => {
              dispatch(notloading());
              setIsSubmitting(false);
              console.log(res);
              if (res.data.Status === 1) {
                Swal.fire({
                  title: facilityname,
                  text: res.data.Message,
                  type: "success",
                  showCancelButton: false,
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.value) {
                    dispatch(notloading());
                  }
                });
              } else {
                dispatch(notloading());
                setIsSubmitting(false);
                Swal.fire({
                  title: facilityname,
                  text: res.data.Message,
                  type: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              }
            })
            .catch((error) => {
              dispatch(notloading());

              Swal.fire({
                title: facilityname,
                text: "Error" + error,
                type: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            });
        } catch (error) {}
      }
    });
  };
  const handleGetLog = async (code) => {
    axios
      .get(serverUrl + "/api/GetListOfRecords", {
        params: {
          param1: code,
          param2: FacilityCode,
          param3: props.ReportingYear,
        },
      })
      .then((res) => {
        setLog(res.data);
        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };
  return (
    <>
      <h1>Submit Report</h1>
      {/* {props.ReportingYear}-{FacilityCode} */}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Classification
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("genInfoClassification", "Classification");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("genInfoClassification");
              setTitle("Classification");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Quality Management
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("genInfoQualityManagement", "Quality Management");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("genInfoQualityManagement");
              setTitle("Quality Management");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Bed Capacity
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("genInfoBedCapacity", "Bed Capacity");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("genInfoBedCapacity");
              setTitle("Bed Capacity");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Summary of patients in the Hospital
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("hospOptSummaryOfPatients", "Summary of patients");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptSummaryOfPatients");
              setTitle("Summary of patients");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Type of Service and Total Discharges According to Specialty
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospOptDischargesSpecialty",
                "Type of Service and Total Discharges According to Specialty"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptDischargesSpecialty");
              setTitle(
                "Type of Service and Total Discharges According to Specialty"
              );
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Ten Leading causes of Morbidity/Diseases Disaggregated based as to Age
          and Sex
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospOptDischargesMorbidity",
                "Ten Leading causes of Morbidity/Diseases Disaggregated based as to Age and Sex"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptDischargesMorbidity");
              setTitle(
                "Ten Leading causes of Morbidity/Diseases Disaggregated based as to Age and Sex"
              );
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Total No. of Deliveries
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospOptDischargesNumberDeliveries",
                "Total no. of Deliveries"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptDischargesNumberDeliveries");
              setTitle("Total no. of Deliveries");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Outpatient Visits, including Emergency Care
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospOptDischargesOPV",
                "Outpatient Visits, including Emergency Care"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptDischargesOPV");
              setTitle("Outpatient Visits, including Emergency Care");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Ten Leading OPD Consultations
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospOptDischargesOPD",
                "Ten Leading OPD Consultations"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptDischargesOPD");
              setTitle("Ten Leading OPD Consultations");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Ten Leading ER Consultations
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospOptDischargesER",
                "Ten Leading ER Consultations"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptDischargesER");
              setTitle("Ten Leading ER Consultations");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Testing and Other Services
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospOptDischargesTesting",
                "Testing and Other Services"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptDischargesTesting");
              setTitle("Testing and Other Services");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Emergency Visits
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("hospOptDischargesEV", "Emergency Visits");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospOptDischargesEV");
              setTitle("Emergency Visits");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Total Number of Deaths
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospitalOperationsDeaths",
                "Total Number of Deaths"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospitalOperationsDeaths");
              setTitle("Total Number of Deaths");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Ten Leading causes of Mortality/Deaths Disaggregated as to Age and Sex
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospitalOperationsMortalityDeaths",
                "Ten Leading causes of Mortality/Deaths Disaggregated as to Age and Sex"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospitalOperationsMortalityDeaths");
              setTitle(
                "Ten Leading causes of Mortality/Deaths Disaggregated as to Age and Sex"
              );
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Healthcare Associated Infections (HAI)
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit(
                "hospitalOperationsHAI",
                "Healthcare Associated Infections (HAI)"
              );
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospitalOperationsHAI");
              setTitle("Healthcare Associated Infections (HAI)");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Major Operations
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("hospitalOperationsMajorOpt", "Major Operations");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospitalOperationsMajorOpt");
              setTitle("Major Operations");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Minor Operations
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("hospitalOperationsMinorOpt", "Minor Operations");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("hospitalOperationsMinorOpt");
              setTitle("Minor Operations");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Staffing
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("staffingPattern", "Staffing");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("staffingPattern");
              setTitle("Staffing");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Expenses
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("expenses", "Expenses");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("expenses");
              setTitle("Expenses");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          Revenues
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleSubmit("revenues", "Revenues");
            }}
          >
            Submit
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              handleGetLog("revenues");
              setTitle("Revenues");
              setShowLog(true);
            }}
          >
            Log
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="secondary"
            disabled={isSubmitted || isSubmitting}
            onClick={() => {
              props.history.push({
                pathname: "/submitreport2",
                state: {
                  ReportingYear: props.ReportingYear,
                },
              });
            }}
          >
            Submit/Finalized Report
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={showLog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowLog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          <MUIDataTable
            className={classes.margin}
            title={title}
            data={log}
            columns={columns}
            options={options}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowLog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(SubmitReport);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";

import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { Button, Grid, Paper, Typography, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
}));
const Classification = (props) => {
  const dispatch = useDispatch();
  const [details, setdetails] = useState([]);
  const [reportingYears, setreportingYears] = useState([]);
  const [serviceCapability, setserviceCapability] = useState([]);
  const [generalClassification, setgeneralClassification] = useState([]);
  const [traumaCapability, settraumaCapability] = useState([]);
  const [specialty, setspecialty] = useState([]);
  const [natureOfOwnership, setnatureOfOwnership] = useState([]);
  const [government, setgovernment] = useState([]);
  const [national, setnational] = useState([]);
  const [local, setlocal] = useState([]);
  const [Private, setPrivate] = useState([]);
  const [Id, setId] = useState("");

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  const userFullname = u.userFullname;
  const username = u.username;
  const facilitycode = u.facilitycode;

  const [firstLoad, setfirstLoad] = useState(true);
  const [isEdit, setisEdit] = useState(details.length <= 0 ? false : true);
  const [oldReportingYear, setoldReportingYear] = useState("");
  const [currentReportingYear, setcurrentReportingYear] = useState("");

  useEffect(() => {
    if (firstLoad) {
      handlegetReportingYear();
      handlegetTableRecords("tblservicecapability");
      handlegetTableRecords("tblgeneralclassification");
      handlegetTableRecords("tbltraumacapability");
      handlegetTableRecords("tblspecialty");
      handlegetTableRecords("tblnatureofownership");
      handlegetTableRecords("tblgovernment");
      handlegetTableRecords("tblnational");
      handlegetTableRecords("tbllocal");
      handlegetTableRecords("tblprivate");
      setfirstLoad(false);
    }
  }, [firstLoad]);

  const classes = useStyles();
  function handleInitialValues() {
    return {
      ReportingYear: isEdit ? details.ReportingYear : currentReportingYear,
      ServiceCapability: isEdit ? details.ServiceCapability : "X",
      GeneralClassification: isEdit ? details.GeneralClassification : "X",
      TraumaCapability: isEdit ? details.TraumaCapability : "X",
      Specialty: isEdit ? details.Specialty : "X",
      OtherSpecialty: isEdit ? details.OtherSpecialty : "",
      NatureOfOwnership: isEdit ? details.NatureOfOwnership : "X",
      Government: isEdit ? details.Government : "X",
      National: isEdit ? details.National : "X",
      Local: isEdit ? details.Local : "X",
      OtherOwnership: isEdit ? details.OtherOwnership : "",
      Private: isEdit ? details.Private : "X",
    };
  }

  const handlegetReportingYear = () => {
    axios
      .get(serverUrl + "/api/getreportingyear")
      .then((res) => {
        console.log(res);
        setreportingYears(res.data);
        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  const handlegetTableRecords = (tblName) => {
    let q = "";

    q = "select * from " + tblName + " order by id";

    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: q,
        },
      })
      .then((res) => {
        if (tblName === "tblservicecapability") {
          setserviceCapability(res.data);
        } else if (tblName === "tblgeneralclassification") {
          setgeneralClassification(res.data);
        } else if (tblName === "tbltraumacapability") {
          settraumaCapability(res.data);
        } else if (tblName === "tblspecialty") {
          setspecialty(res.data);
        } else if (tblName === "tblnatureofownership") {
          setnatureOfOwnership(res.data);
        } else if (tblName === "tblgovernment") {
          setgovernment(res.data);
        } else if (tblName === "tblnational") {
          setnational(res.data);
        } else if (tblName === "tbllocal") {
          setlocal(res.data);
        } else if (tblName === "tblprivate") {
          setPrivate(res.data);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  function validateFields(values) {
    const errors: Partial<Values> = {};
    if (values.ServiceCapability !== "1") {
      values.GeneralClassification = "X";
    }

    if (values.ReportingYear === "" || values.ReportingYear === null) {
      errors.ReportingYear = "Please select reporting year!";
    }

    if (values.ServiceCapability === "X" || values.ServiceCapability === null) {
      errors.ServiceCapability = "Please select Service Capability!";
    }

    if (values.ServiceCapability === "1") {
      if (
        values.GeneralClassification === "X" ||
        values.GeneralClassification === null
      ) {
        errors.GeneralClassification = "Please select General Classification!";
      }
    }

    if (values.ServiceCapability === "2") {
      console.log(values.TraumaCapability);
      if (values.Specialty === "X" || values.Specialty === null) {
        errors.Specialty = "Please select Specialty!";
      }

      if (values.Specialty === "4") {
        if (values.OtherSpecialty === "") {
          errors.OtherSpecialty = "Please encode Other Specialty!";
        }
      } else {
        values.OtherSpecialty = "";
      }
    }

    if (values.NatureOfOwnership === "X" || values.NatureOfOwnership === null) {
      errors.NatureOfOwnership = "Please select Nature Of Ownership!";
    } else {
      if (values.NatureOfOwnership === "1") {
        if (values.Government === "X" || values.Government === null) {
          errors.Government = "Please select type of Government ownership!";
        }
      }
    }

    if (values.Government === "1") {
      if (values.National === "X" || values.National === null) {
        errors.National = "Please select National ownership!";
      }
    } else {
      values.National = "X";
    }

    if (values.Government === "2") {
      if (values.Local === "X" || values.Local === null) {
        errors.Local = "Please select Local ownership!";
      }
    } else {
      values.Local = "X";
    }

    if (values.NatureOfOwnership === "2") {
      if (values.Private === "X" || values.Private === null) {
        errors.Private = "Please select Private ownership!";
      }
    }
    if (values.ServiceCapability === "1" || values.ServiceCapability === "2") {
      if (values.TraumaCapability === "X" || values.TraumaCapability === null) {
        errors.TraumaCapability = "Please select Trauma Capability!";
      }
    }

    return errors;
  }

  function handleReportingYearChange(values) {
    setcurrentReportingYear(values.ReportingYear);
    if (values.ReportingYear === "") return;
    if (values.ReportingYear === oldReportingYear) return;
    setoldReportingYear(values.ReportingYear);

    let q = "";

    q =
      "select * from tblClassification where reportingyear='" +
      values.ReportingYear +
      "' and facilitycode='" +
      facilitycode +
      "'";
    console.log(q);
    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: q,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          console.log(res.data[0]);
          setdetails(res.data[0]);
          setId(res.data[0].Id);

          setisEdit(true);
        } else {
          setId("");
          setisEdit(false);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  }

  const handleChageServiceCapability = (x) => {};
  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());
    var token = localStorage.getItem("token_id");
    var headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    const valuestosave = {
      ...values,
      userFullname: userFullname,
      username: username,
      Id: Id,
    };

    axios
      .post(serverUrl + "/api/saveclassification", valuestosave, headers)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());

              props.history.push("/");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" align="center">
              Classification
            </Typography>
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  <Field
                    type="text"
                    label="Reporting Year"
                    select
                    margin="dense"
                    component={TextField}
                    onChange={handleReportingYearChange(values)}
                    fullWidth
                    values={values.ReportingYear || ""}
                    required={true}
                    name="ReportingYear"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {reportingYears.map((option) => (
                      <MenuItem
                        key={option.ReportingYear}
                        value={option.ReportingYear}
                      >
                        {option.ReportingYear}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="text"
                    label="Service Capability"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    required
                    values={values.ServiceCapability}
                    onChange={handleChageServiceCapability(
                      values.ServiceCapability
                    )}
                    name="ServiceCapability"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {serviceCapability.map((option) => (
                      <MenuItem key={option.sccode} value={option.sccode}>
                        {option.scdesc}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="text"
                    label="General Classification"
                    select
                    margin="dense"
                    component={TextField}
                    disabled={values.ServiceCapability !== "1"}
                    fullWidth
                    values={values.GeneralClassification}
                    name="GeneralClassification"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {generalClassification.map((option) => (
                      <MenuItem key={option.scgcode} value={option.scgcode}>
                        {option.scgdesc}
                      </MenuItem>
                    ))}
                  </Field>

                  <Field
                    type="text"
                    label="Specialty"
                    select
                    margin="dense"
                    component={TextField}
                    disabled={values.ServiceCapability !== "2"}
                    fullWidth
                    values={values.Specialty}
                    name="Specialty"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {specialty.map((option) => (
                      <MenuItem key={option.scscode} value={option.scscode}>
                        {option.scsdesc}
                      </MenuItem>
                    ))}
                  </Field>

                  <Field
                    type="text"
                    label="Other Specialty"
                    name="OtherSpecialty"
                    placeholder="Other Specialty"
                    component={TextField}
                    // variant="outlined"
                    disabled={values.Specialty !== "4"}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.OtherSpecialty !== "",
                    }}
                  />

                  <Field
                    type="text"
                    label="Trauma Capability"
                    select
                    margin="dense"
                    component={TextField}
                    disabled={
                      values.ServiceCapability === "3" ||
                      values.ServiceCapability === "X"
                    }
                    fullWidth
                    values={values.TraumaCapability}
                    name="TraumaCapability"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {traumaCapability.map((option) => (
                      <MenuItem key={option.tccode} value={option.tccode}>
                        {option.tcdesc}
                      </MenuItem>
                    ))}
                  </Field>

                  <Paper className={classes.root}>
                    <Typography variant="h5" component="h3" align="center">
                      Nature of Ownership
                    </Typography>
                    <Field
                      type="text"
                      label="Nature of Ownership"
                      select
                      margin="dense"
                      component={TextField}
                      fullWidth
                      required
                      values={values.NatureOfOwnership}
                      name="NatureOfOwnership"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {natureOfOwnership.map((option) => (
                        <MenuItem key={option.nocode} value={option.nocode}>
                          {option.nodesc}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      type="text"
                      label="Government"
                      select
                      margin="dense"
                      component={TextField}
                      fullWidth
                      values={values.Government}
                      disabled={values.NatureOfOwnership !== "1"}
                      name="Government"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {government.map((option) => (
                        <MenuItem key={option.nogcode} value={option.nogcode}>
                          {option.nogdesc}
                        </MenuItem>
                      ))}
                    </Field>

                    <Field
                      type="text"
                      label="National"
                      select
                      margin="dense"
                      component={TextField}
                      fullWidth
                      values={values.National}
                      disabled={values.Government !== "1"}
                      name="National"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {national.map((option) => (
                        <MenuItem key={option.nogncode} value={option.nogncode}>
                          {option.nogndesc}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      type="text"
                      label="Local"
                      select
                      margin="dense"
                      component={TextField}
                      fullWidth
                      values={values.Local}
                      name="Local"
                      disabled={values.Government !== "2"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {local.map((option) => (
                        <MenuItem key={option.noglcode} value={option.noglcode}>
                          {option.nogldesc}
                        </MenuItem>
                      ))}
                    </Field>

                    <Field
                      type="text"
                      label="Private"
                      select
                      margin="dense"
                      component={TextField}
                      fullWidth
                      values={values.Private}
                      name="Private"
                      disabled={values.NatureOfOwnership !== "2"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {Private.map((option) => (
                        <MenuItem key={option.nopcode} value={option.nopcode}>
                          {option.nopdesc}
                        </MenuItem>
                      ))}
                    </Field>

                    <Field
                      type="text"
                      label="Other Ownership"
                      name="OtherOwnership"
                      placeholder="Other Ownership"
                      component={TextField}
                      // variant="outlined"

                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.OtherOwnership !== "",
                      }}
                    />
                  </Paper>

                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Classification);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";

import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { Button, Grid, Paper, Typography, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
}));
const Testing = (props) => {
  const dispatch = useDispatch();
  const [details, setdetails] = useState([]);
  const [reportingYears, setreportingYears] = useState([]);

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  const userFullname = u.userFullname;

  const facilitycode = u.facilitycode;

  const [firstLoad, setfirstLoad] = useState(true);
  const [isEdit, setisEdit] = useState(details.length <= 0 ? false : true);
  const [oldReportingYear, setoldReportingYear] = useState("");
  const [currentReportingYear, setcurrentReportingYear] = useState("");

  useEffect(() => {
    if (firstLoad) {
      handlegetReportingYear();

      setfirstLoad(false);
    }
  }, [firstLoad]);

  const classes = useStyles();
  function handleInitialValues() {
    return {
      ReportingYear: isEdit ? details.ReportingYear : currentReportingYear,
      Xray: isEdit ? details.Xray : "",
      Ultrasound: isEdit ? details.Ultrasound : "",
      CtScan: isEdit ? details.CtScan : "",
      Mri: isEdit ? details.Mri : "",
      Mammography: isEdit ? details.Mammography : "",
      Angiography: isEdit ? details.Angiography : "",
      LinearAccelerator: isEdit ? details.LinearAccelerator : "",
      DentalXRay: isEdit ? details.DentalXRay : "",
      Others: isEdit ? details.Others : "",
      Urinalysis: isEdit ? details.Urinalysis : "",
      Fecalysis: isEdit ? details.Fecalysis : "",
      Hematology: isEdit ? details.Hematology : "",
      ClinicalChemistry: isEdit ? details.ClinicalChemistry : "",
      ImmunologySerologyHIV: isEdit ? details.ImmunologySerologyHIV : "",
      Microbiology: isEdit ? details.Microbiology : "",
      SurgicalPathology: isEdit ? details.SurgicalPathology : "",
      Autopsy: isEdit ? details.Autopsy : "",
      Cytology: isEdit ? details.Cytology : "",
      BloodUnitsTransfused: isEdit ? details.BloodUnitsTransfused : "",
    };
  }

  const handlegetReportingYear = () => {
    axios
      .get(serverUrl + "/api/getreportingyear")
      .then((res) => {
        console.log(res);
        setreportingYears(res.data);
        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  function validateFields(values) {
    const errors: Partial<Values> = {};

    if (values.ReportingYear === "" || values.ReportingYear === null) {
      errors.ReportingYear = "Please select reporting year!";
    }

    return errors;
  }

  function handleReportingYearChange(values) {
    setcurrentReportingYear(values.ReportingYear);
    if (values.ReportingYear === "") return;
    if (values.ReportingYear === oldReportingYear) return;
    setoldReportingYear(values.ReportingYear);

    let q = "";

    q =
      "select * from tblTesting where reportingyear='" +
      values.ReportingYear +
      "' and facilitycode='" +
      facilitycode +
      "'";

    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: q,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          console.log(res.data[0]);
          setdetails(res.data[0]);

          setisEdit(true);
        } else {
          setisEdit(false);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  }

  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());

    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    const valuestosave = {
      ...values,
      UserName: userFullname,
      FacilityCode: facilitycode,
    };

    axios
      .post(serverUrl + "/api/savetesting", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());

              // props.history.push("/");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" align="center">
              Testing
            </Typography>
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  <Field
                    type="text"
                    label="Reporting Year"
                    select
                    margin="dense"
                    component={TextField}
                    onChange={handleReportingYearChange(values)}
                    fullWidth
                    values={values.ReportingYear || ""}
                    required={true}
                    name="ReportingYear"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {reportingYears.map((option) => (
                      <MenuItem
                        key={option.ReportingYear}
                        value={option.ReportingYear}
                      >
                        {option.ReportingYear}
                      </MenuItem>
                    ))}
                  </Field>

                  <Paper className={classes.root}>
                    <Typography variant="h5" component="h3" align="center">
                      Total number of medical imaging tests (all types including
                      x-rays, ultrasound, CT scans, etc.)
                    </Typography>

                    <Field
                      type="number"
                      label="Xray"
                      name="Xray"
                      placeholder="Xray"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Xray !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Ultrasound"
                      name="Ultrasound"
                      placeholder="Ultrasound"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Ultrasound !== "",
                      }}
                    />

                    <Field
                      type="number"
                      label="CtScan"
                      name="CtScan"
                      placeholder="CtScan"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.CtScan !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Mri"
                      name="Mri"
                      placeholder="Mri"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Mri !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Mammography"
                      name="Mammography"
                      placeholder="Mammography"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Mammography !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Angiography"
                      name="Angiography"
                      placeholder="Angiography"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Angiography !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="LinearAccelerator"
                      name="LinearAccelerator"
                      placeholder="LinearAccelerator"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.LinearAccelerator !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="DentalXRay"
                      name="DentalXRay"
                      placeholder="DentalXRay"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.DentalXRay !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Others"
                      name="Others"
                      placeholder="Others"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Others !== "",
                      }}
                    />
                  </Paper>
                  <Paper className={classes.root}>
                    <Typography variant="h5" component="h3" align="center">
                      Total number of laboratory and diagnostic tests (all
                      types, excluding medical imaging)
                    </Typography>

                    <Field
                      type="number"
                      label="Urinalysis"
                      name="Urinalysis"
                      placeholder="Urinalysis"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Urinalysis !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Fecalysis"
                      name="Fecalysis"
                      placeholder="Fecalysis"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Fecalysis !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Hematology"
                      name="Hematology"
                      placeholder="Hematology"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Hematology !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Clinical Chemistry"
                      name="ClinicalChemistry"
                      placeholder="Clinical Chemistry"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.ClinicalChemistry !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="ImmunologySerologyHIV"
                      name="ImmunologySerologyHIV"
                      placeholder="ImmunologySerologyHIV"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.ImmunologySerologyHIV !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Microbiology"
                      name="Microbiology"
                      placeholder="Microbiology"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Urinalysis !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Surgical Pathology"
                      name="SurgicalPathology"
                      placeholder="Surgical Pathology"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.SurgicalPathology !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Autopsy"
                      name="Autopsy"
                      placeholder="Autopsy"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Autopsy !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Cytology"
                      name="Cytology"
                      placeholder="Cytology"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.Cytology !== "",
                      }}
                    />
                  </Paper>
                  <Paper className={classes.root}>
                    <Typography variant="h5" component="h3" align="center">
                      Blood Service Facilities
                    </Typography>
                    <Field
                      type="number"
                      label="Blood Units Transfused"
                      name="BloodUnitsTransfused"
                      placeholder="Blood Units Transfused"
                      component={TextField}
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.BloodUnitsTransfused !== "",
                      }}
                    />
                  </Paper>
                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Testing);

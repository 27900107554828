/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import { GetListOfRecords } from "../Utilities";

import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
const UserProfile = (props) => {
  const dispatch = useDispatch();
  const [details, setdetails] = useState([]);
  const Id = props.location.state.id;
  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));

  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));
  const userFullname = u.userFullname;

  const [returnUrl] = useState(props.location.state.returnUrl);
  const [firstLoad, setfirstLoad] = useState(true);
  const [isEdit, setisEdit] = useState(false);
  const [facilityCode, setfacilityCode] = useState([]);
  const requirePassword = props.location.state.requirePassword;
  const enableAdmin = props.location.state.enableAdmin;
  useEffect(() => {
    if (firstLoad) {
      GetListOfRecords("select * from tblFacilities").then((res) =>
        setfacilityCode(res)
      );

      if (Id !== "") {
        setisEdit(true);
        handleGetDetails();
      }

      setfirstLoad(false);
    }
  }, []);

  const classes = useStyles();
  function handleGetDetails() {
    console.log(Id);
    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: "select * from tblUsers where id='" + Id + "'",
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          console.log(res.data[0]);
          setdetails(res.data[0]);

          setisEdit(true);
        } else {
          setisEdit(false);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  }
  function handleClick(x) {
    props.history.push(x);
  }
  function handleInitialValues() {
    return {
      UserName: isEdit ? details.UserName : "",
      FullName: isEdit ? details.FullName : "",
      FacilityCode: isEdit
        ? details.FacilityCode === undefined
          ? ""
          : details.FacilityCode
        : "",
      EmailAddress: isEdit ? details.EmailAddress : "",
      ContactNos: isEdit ? details.ContactNos : "",
      Password: "",
      ConfirmPassword: "",
    };
  }

  function validateFields(values) {
    const errors: Partial<Values> = {};
    console.log(values);
    if (!values.UserName) {
      errors.UserName = "Username is required!";
    }

    if (values.FacilityCode === null || values.FacilityCode === "") {
      errors.FacilityCode = "Facility Code is required!";
    }

    if (!values.FullName) {
      errors.FullName = "Fullname is required!";
    }

    if (values.EmailAddress) {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.EmailAddress)
      ) {
        errors.EmailAddress = "Invalid email address!";
      }
    }

    if (Id === "") {
      if (!values.Password) {
        errors.Password = "Password is required!";
      } else {
        if (values.Password.length < 5) {
          errors.Password = "Password length must be 5 characters or more!";
        }
      }

      if (!values.ConfirmPassword) {
        errors.ConfirmPassword = "Please confirm the password!";
      }
      if (values.Password !== values.ConfirmPassword) {
        errors.ConfirmPassword = "Passwords do not match!";
      }
    } else {
    }

    if (requirePassword === true) {
      if (!values.Password) {
        errors.Password = "Password is required!";
      }
    }
    console.log(errors);
    return errors;
  }

  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());

    const valuestosave = {
      ...values,
      userFullname: userFullname,
      Id: Id,
    };

    axios
      .post(serverUrl + "/api/registeruser", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());

              props.history.push(returnUrl);
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" align="center">
              Quality Management
            </Typography>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                href="#"
                onClick={() => handleClick("/home")}
              >
                Home
              </Link>
              <Link
                color="inherit"
                href="#"
                onClick={() => handleClick("/users")}
              >
                Users
              </Link>
            </Breadcrumbs>
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  <Field
                    type="text"
                    label="Username"
                    name="UserName"
                    placeholder="Username"
                    value={values.UserName}
                    component={TextField}
                    margin="dense"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: values.UserName !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Fullname"
                    name="FullName"
                    placeholder="Fullname"
                    value={values.FullName}
                    component={TextField}
                    margin="dense"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: values.FullName !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Facility Code"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    values={values.FacilityCode}
                    name="FacilityCode"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {facilityCode.map((option) => (
                      <MenuItem
                        key={option.FacilityCode}
                        value={option.FacilityCode}
                      >
                        {option.FacilityName}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="email"
                    label="Email Address"
                    name="EmailAddress"
                    placeholder="Email Address"
                    component={TextField}
                    values={values.EmailAddress}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.EmailAddress !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Contact Nos."
                    name="ContactNos"
                    placeholder="Contact Nos."
                    value={values.ContactNos}
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.ContactNos !== "",
                    }}
                  />
                  <Field
                    type="password"
                    label="Password"
                    name="Password"
                    placeholder="Password"
                    value={values.Password}
                    component={TextField}
                    disabled={!requirePassword}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Password !== "",
                    }}
                  />
                  <Field
                    type="password"
                    label="Confirm Password"
                    name="ConfirmPassword"
                    value={values.ConfirmPassword}
                    placeholder="Confirm Password"
                    disabled={Id !== ""}
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.ConfirmPassword !== "",
                    }}
                  />
                  Administrator{" "}
                  <Field
                    label="Administrator"
                    name="Administrator"
                    color="primary"
                    disabled={enableAdmin === false}
                    component={Checkbox}
                    /*           values={
                      values.Administrator != null
                        ? values.Administrator
                        : false
                    } */
                  />
                  Active{" "}
                  <Field
                    label="Active"
                    name="Active"
                    color="primary"
                    disabled={Id === "" || requirePassword === true}
                    component={Checkbox}
                  />
                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </div>
  );
};

export default withRouter(UserProfile);

const loggedReducer = (
  state = {
    username: "",
    userFullname: "",
    isLogged: false,
    userid: "",
    token: ""
  },
  action
) => {
  switch (action.type) {
    case "LOGGED_IN":
      return action.payload;
    case "LOGGED_OUT":
      return {
        username: "",
        userFullname: "",
        isLogged: false,
        userid: "",
        token: ""
      };
    default:
      return state;
  }
};

export default loggedReducer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { setloading, notloading } from "../../actions";
import SubmitReport from "./SubmitReport";
import {
  Paper,
  Box,
  Typography,
  MenuItem,
  Select,
  Tabs,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
    width: "1200px",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
const EmrSubmission = () => {
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();
  const [reportingYears, setreportingYears] = useState([]);
  const [ReportingYear, setReportingYear] = useState("");

  const [classificationBlob, setClassificationBlob] = useState(null);

  const [qualityManagementBlob, setQualityMangementBlob] = useState(null);
  const [bedCapacityBlob, setBedCapacityBlob] = useState(null);
  const [reportBlob, setReportBlob] = useState(null);

  const classes = useStyles();
  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;
  const handlegetReportingYear = () => {
    axios
      .get(serverUrl + "/api/getreportingyear")
      .then((res) => {
        setreportingYears(res.data);
        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetReport = async () => {
    let reportName = "";
    setReportBlob(null);

    if (value === 19) return;
    dispatch(setloading());
    if (value === 0) {
      reportName = "classification.rpt";
    } else if (value === 1) {
      reportName = "qualitymanagement.rpt";
    } else if (value === 2) {
      reportName = "bedcapacity.rpt";
    } else if (value === 3) {
      reportName = "summaryofpatients.rpt";
    } else if (value === 4) {
      reportName = "typeofserviceandtotaldischarges.rpt";
    } else if (value === 5) {
      reportName = "causesofmorbidity.rpt";
    } else if (value === 6) {
      reportName = "causesofmorbiditybyage.rpt";
    } else if (value === 7) {
      reportName = "totalnoofdeliveries.rpt";
    } else if (value === 8) {
      reportName = "opdvisits.rpt";
    } else if (value === 9) {
      reportName = "leadingopdconsultations.rpt";
    } else if (value === 10) {
      reportName = "leadingerconsultations.rpt";
    } else if (value === 11) {
      reportName = "testing.rpt";
    } else if (value === 12) {
      reportName = "deaths.rpt";
    } else if (value === 13) {
      reportName = "agedistributionmortality.rpt";
    } else if (value === 14) {
      reportName = "hai.rpt";
    } else if (value === 15) {
      reportName = "surgicaloperations.rpt";
    } else if (value === 16) {
      reportName = "staffing.rpt";
    } else if (value === 17) {
      reportName = "expenses.rpt";
    } else if (value === 18) {
      reportName = "revenues.rpt";
    } else if (value === 20) {
      reportName = "PatientsWithNoDiagnosis.rpt";
    }

    try {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token_id");
      await axios({
        url: localStorage.getItem("serverUrl") + "/api/downloadreport", //your url
        method: "GET",
        responseType: "blob", // important
        params: {
          reportName: reportName,
          facilityCode: localStorage.getItem("facilityCode"),
          reportingYear: ReportingYear,
        },
      }).then((response) => {
        let newBlob;
        newBlob = new Blob([response.data], {
          type: "application/pdf",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        if (value === 0) {
          setClassificationBlob(data);
        } else if (value === 1) {
          setQualityMangementBlob(data);
        } else if (value === 2) {
          setBedCapacityBlob(data);
        } else {
          setReportBlob(data);
        }
        dispatch(notloading());
      });
    } catch (error) {}
  };
  useEffect(() => {
    handlegetReportingYear();
  }, []);

  useEffect(() => {
    handleGetReport();
  }, [ReportingYear, value]);

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" component="h3" align="center">
        EMR Submission
      </Typography>
      {"Reporting Year: "}
      <Select
        labelid="Reporting Years"
        name="ReportingYear"
        id="ReportingYear"
        value={ReportingYear}
        onChange={(e) => setReportingYear(e.target.value)}
        // onChange={handleReportingYearChange(state.ReportingYear)}
      >
        {reportingYears.map((option) => (
          <MenuItem key={option.Id} value={option.ReportingYear}>
            {option.ReportingYear}
          </MenuItem>
        ))}
      </Select>

      <div className={classes.root}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          className={classes.tabs}
        >
          <Tab
            label="A. Classification"
            {...a11yProps(0)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="B. Quality Management"
            {...a11yProps(1)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="C. Bed Capacity"
            {...a11yProps(2)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="II-A. Summary of patients in the hospital"
            {...a11yProps(3)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="II-B Type of service and total discharges"
            {...a11yProps(4)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Causes of morbidity"
            {...a11yProps(5)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Diseases Disaggregated based as to Age and Sex"
            {...a11yProps(6)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Total no. of deliveries"
            {...a11yProps(7)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Outpatient Visits, including Emergency Care, Testing and Other"
            {...a11yProps(8)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Leading OPD Consultations"
            {...a11yProps(9)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Leading E.R. Consultations"
            {...a11yProps(10)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Testing and Other Services"
            {...a11yProps(11)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Deaths"
            {...a11yProps(12)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Age Distribution of Patient (Deaths)"
            {...a11yProps(13)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="D. Healthcare Associated Infections (HAI)"
            {...a11yProps(14)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="E. Surgical Operations"
            {...a11yProps(15)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="III. Staffing"
            {...a11yProps(16)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="IV. Expenses"
            {...a11yProps(17)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="V. Revenues"
            {...a11yProps(18)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Submit Report"
            {...a11yProps(19)}
            disabled={ReportingYear === ""}
          />
          <Tab
            label="Patients with no diagnosis"
            {...a11yProps(20)}
            disabled={ReportingYear === ""}
          />
        </Tabs>
        {/* <TabPanel value={value} index={20}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel> */}
        <TabPanel value={value} index={0}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={classificationBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={qualityManagementBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={bedCapacityBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={4}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={5}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={6}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={7}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={8}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={9}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={10}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={11}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={12}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={13}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={14}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={15}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={16}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={17}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={18}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
        <TabPanel value={value} index={19}>
          {ReportingYear !== "" && (
            <SubmitReport
              ReportingYear={ReportingYear}
              FacilityCode={localStorage.getItem("facilityCode")}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={20}>
          {ReportingYear !== "" && (
            <iframe
              title="xxx"
              src={reportBlob}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          )}
        </TabPanel>
      </div>
    </Paper>
  );
};

export default EmrSubmission;

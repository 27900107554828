import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";
import { makeStyles } from "@material-ui/core/styles";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { Button, MenuItem, Menu, TableRow, TableCell } from "@material-ui/core";
import {
  faUserEdit,
  faPlusSquare,
  faMedkit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  buttonMargin: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  marginr: {
    marginRight: 4,
  },
  marginT: {
    marginTop: 50,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  marginGrid: {
    marginTop: 75,
  },
}));
const Patients = (props) => {
  const dispatch = useDispatch();

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const facilityName = s.facilityName;

  const [rows, setRows] = useState([]);
  const [page, setpage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [totalCount, settotalCount] = useState(100);
  const [searchText, setsearchText] = useState("");
  const [reloadData, setreloadData] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsSelected, setrowsSelected] = useState([]);
  const [sortField, setsortField] = useState("patientname");
  const [sortDir, setsortDir] = useState("ascending");
  useEffect(() => {
    if (reloadData) {
      fetchData();
      setreloadData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const classes = useStyles();

  const handleCloseAction = (param) => {
    setAnchorEl(null);
  };

  const handleNew = () => {
    props.history.push({
      pathname: "/admissiondetails",
      state: { Id: "", PatientId: "" },
    });
  };

  const handleEdit = () => {
    props.history.push({
      pathname: "/admissiondetails",
      state: {
        Id: rows[rowsSelected].CaseId,
        PatientId: rows[rowsSelected].PatientId,
      },
    });
  };

  const handleReAdmitPatient = () => {
    props.history.push({
      pathname: "/admissiondetails",
      state: { PatientId: rows[rowsSelected].PatientId, Id: "" },
    });
  };

  const handleConditionOnDischarge = () => {
    props.history.push({
      pathname: "/conditionondischarge",
      state: {
        CaseId: rows[rowsSelected].CaseId,
        PatientName: rows[rowsSelected].PatientName,
        ConditionOnDischarge: rows[rowsSelected].FkCodetblConditionOnDischarge,
        VentilatorDays: rows[rowsSelected].VentilatorDays,
        CentralLine: rows[rowsSelected].CentralLine,
        CatheterDays: rows[rowsSelected].CatheterDays,
        CleanProcedure: rows[rowsSelected].CleanProcedure,
        WithInfection: rows[rowsSelected].WithInfection,
      },
    });
  };

  const columns = [
    {
      name: "CaseId",
      label: "ID",
      display: false,
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "PatientId",
      label: "ID",
      options: {
        display: "false",
        filter: false,
      },
    },
    {
      name: "PatientName",
      display: true,
      label: "Patient name",
      options: {
        display: "true",
        filter: false,
        sort: true,
      },
    },
    {
      name: "TypeOfPatient",
      label: "Type Of Patient",
      options: {
        display: "true",
        filter: false,
        sort: false,
      },
    },
    {
      name: "DateAdmittedStr",
      display: true,
      label: "Date Admitted",
      options: {
        display: "true",
        filter: false,
        sort: false,
      },
    },
    {
      name: "DateDischargedStr",
      display: true,
      label: "Date Discharged",
      options: {
        display: "true",
        filter: false,
        sort: false,
      },
    },
    {
      name: "BirthDateStr",
      label: "Birth Date",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "Address",
      label: "Address",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: "AddedBy",
      display: false,
      label: "Added By",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "DateAdded",
      label: "Date Added",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "UpdatedBy",
      label: "Updated By",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "DateUpdated",
      label: "Date Updated",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "FkCodetblConditionOnDischarge",
      label: "Condition On Discharge",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "ConditionOnDischarge",
      label: "Condition On Discharge",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "VentilatorDays",
      label: "VentilatorDays",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "CentralLine",
      label: "CentralLine",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "CatheterDays",
      label: "CatheterDays",
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: "CleanProcedure",
      label: "CleanProcedure",
      options: {
        sort: false,
        display: false,
      },
    },
  ];

  const fetchData = () => {
    dispatch(setloading());
    var userdetails = localStorage.getItem("userdetails");
    var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
    var s = JSON.parse(udetails.toString(CryptoENC));

    var settingsE = localStorage.getItem("settings");
    var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
    var s1 = JSON.parse(settings.toString(CryptoENC));
    const serverUrl = s1.serverUrl;

    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(serverUrl + "/api/searchadmissions", {
        params: {
          searchText: searchText,
          sortfield: sortField,
          sortdir: sortDir === "ascending" ? "asc" : "desc",
          pageno: page,
          rows: rowsPerPage,
          username: s.username,
        },
      })
      .then((res) => {
        settotalCount(parseInt(res.data.totalrows));
        setRows(res.data.lst);

        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityName,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  const onTableChange = (action, tableState) => {
    if (action === "search") {
      setsearchText(tableState.searchText);
      handleSearch();
    }

    if (action === "changePage") {
      setpage(tableState.page);
      setreloadData(true);
    }
  };

  const handleSearch = () => {
    fetchData();
  };
  const onColumnSortChange = (changedColumn, direction) => {
    if (sortDir === "ascending") {
      setsortDir("descending");
    } else {
      setsortDir("ascending");
    }

    setsortField(changedColumn);
    setreloadData(true);
  };

  const options = {
    filterType: "dropdown",
    search: true,
    viewColumns: false,
    filter: false,
    selectableRows: "single",
    selectableRowsOnClick: true,
    serverSide: true,
    pagination: true,
    page: page,
    customToolbarSelect: () => {},
    responsive: "scrollMaxHeight",
    onTableChange: (action, tableState) => onTableChange(action, tableState),
    onColumnSortChange: (changedColumn, direction) =>
      onColumnSortChange(changedColumn, direction),
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 100],
    count: totalCount,
    rowsSelected: rowsSelected,
    onRowsSelect: (rowsSelected, allRows) => {
      setrowsSelected(allRows.map((row) => row.dataIndex));
    },
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const r = JSON.parse(JSON.stringify(rowData));
      console.log(r);
      return (
        <TableRow>
          <TableCell colSpan={rowData.length}>
            {/* Custom expandable row option. Data: {JSON.stringify(rowData)} */}
            <div>Date Of Birth:{r[6]}</div>
            <div>Address:{r[7]}</div>
            <div>Condition On Discharge:{r[13]}</div>
            <div>Added By:{r[8]}</div>
            <div>Date Added:{r[9]}</div>
            <div>Updated By:{r[10]}</div>
            <div>Date Updated:{r[11]}</div>
            <div>Patient Id:{r[1]}</div>
            <div>Case Id:{r[0]}</div>
          </TableCell>
        </TableRow>
      );
    },
    searchText: searchText,
    searchPlaceholder: "Encode string to be searched!",
    customSearch: (searchQuery, currentRow, columns) => {
      setsearchText(searchQuery);
      return false;
    },
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.buttonMargin}
        onClick={handleNew}
      >
        <FontAwesomeIcon icon={faPlusSquare} className={classes.marginr} />
        New
      </Button>
      <Button
        variant="contained"
        size="small"
        onClick={() => handleEdit()}
        color="primary"
        className={classes.buttonMargin}
        disabled={rowsSelected.length <= 0}
      >
        <FontAwesomeIcon icon={faUserEdit} className={classes.marginr} />
        Edit
      </Button>

      <Button
        variant="contained"
        size="small"
        onClick={() => handleReAdmitPatient()}
        color="primary"
        className={classes.buttonMargin}
        disabled={rowsSelected.length <= 0}
      >
        <FontAwesomeIcon icon={faMedkit} className={classes.marginr} />
        Re-Admit Patient
      </Button>

      <Button
        variant="contained"
        size="small"
        onClick={() => handleConditionOnDischarge()}
        color="primary"
        className={classes.buttonMargin}
        disabled={rowsSelected.length <= 0}
      >
        <FontAwesomeIcon icon={faMedkit} className={classes.marginr} />
        Condition on discharge
      </Button>
      {/*    <Button
        variant="contained"
        size="small"
        onClick={() => handleDiagnosis()}
        color="primary"
        className={classes.buttonMargin}
        disabled={rowsSelected.length <= 0}
      >
        <FontAwesomeIcon icon={faMedkit} className={classes.marginr} />
        Diagnosis
      </Button> */}
      {/*    <Button
        variant="contained"
        size="small"
        onClick={() => handleSurgicalOperations()}
        color="primary"
        className={classes.buttonMargin}
        disabled={rowsSelected.length <= 0}
      >
        <FontAwesomeIcon icon={faUserMd} className={classes.marginr} />
        Surgical Operations
      </Button> */}

      {/*       <Button
        variant="contained"
        size="small"
        onClick={() => setopenCodDialog(true)}
        color="primary"
        className={classes.buttonMargin}
        disabled={rowsSelected.length <= 0}
      >
        <FontAwesomeIcon icon={faUserMd} className={classes.marginr} />
        Test Done
      </Button> */}

      {/*      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.margin}
        onClick={handleClickAction}
        aria-controls="simple-menu"
        aria-haspopup="true"
      >
        <FontAwesomeIcon icon={faFolderOpen} className={classes.marginr} />
        Action
      </Button> */}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseAction}
      >
        <MenuItem onClick={() => handleCloseAction("cod")}>
          Condition on discharge
        </MenuItem>
        <MenuItem onClick={() => handleCloseAction("surgical")}>
          Surgical Operations
        </MenuItem>
      </Menu>
      <MUIDataTable
        className={classes.margin}
        title={"List of patients"}
        data={rows}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default withRouter(Patients);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  MenuItem,
} from "@material-ui/core";
import { TextField, CheckboxWithLabel } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
const QualityManagement = (props) => {
  const dispatch = useDispatch();
  const [details, setdetails] = useState([]);
  const [reportingYears, setreportingYears] = useState([]);

  const Id = props.location.state.Id;
  console.log(Id);

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  console.log(s);
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));
  console.log(u);
  const userFullname = u.userFullname;

  const username = u.username;

  const [firstLoad, setfirstLoad] = useState(true);
  const [isEdit, setisEdit] = useState(false);
  const [qualitymanagementtype, setqualitymanagementtype] = useState([]);
  const [philhealthAccreditation, setphilhealthAccreditation] = useState([]);

  useEffect(() => {
    if (firstLoad) {
      handlegetTableRecords("tblreportingyear");
      handlegetTableRecords("tblqualitymanagementutil");
      handlegetTableRecords("tblPhilhealthAccreditation");

      if (Id !== "") {
        setisEdit(true);
        handleGetDetails();
      }

      setfirstLoad(false);
    }
  }, [firstLoad]);

  const classes = useStyles();
  function handleGetDetails() {
    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: "select * from tblQualityManagement where id='" + Id + "'",
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setdetails(res.data[0]);

          setisEdit(true);
        } else {
          setisEdit(false);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  }
  function handleClick(x) {
    props.history.push(x);
  }
  function handleInitialValues() {
    return {
      ReportingYear: isEdit ? details.ReportingYear : "",
      QualityManagementType: isEdit ? details.QualityManagementType : "X",
      IsoCertifyingBody: isEdit ? details.IsoCertifyingBody : "",
      Description: isEdit ? details.Description : "",
      PhilhealthAccreditation: isEdit ? details.PhilhealthAccreditation : "X",
      ValidityFrom: isEdit ? details.ValidityFrom : "",
      ValidityTo: isEdit ? details.ValidityTo : "",
      Deleted: isEdit ? details.Deleted : false,
    };
  }

  const handlegetTableRecords = (tblName) => {
    let q = "";

    q = "select * from " + tblName + " order by id";

    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: q,
        },
      })
      .then((res) => {
        if (tblName === "tblqualitymanagementutil") {
          setqualitymanagementtype(res.data);
        } else if (tblName === "tblreportingyear") {
          setreportingYears(res.data);
        } else if (tblName === "tblPhilhealthAccreditation") {
          setphilhealthAccreditation(res.data);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  function validateFields(values) {
    const errors: Partial<Values> = {};
    if (values.ReportingYear === "" || values.ReportingYear === null) {
      errors.ReportingYear = "Please select reporting year!";
    }
    if (
      values.QualityManagementType === "" ||
      values.QualityManagementType === null ||
      values.QualityManagementType === "X"
    ) {
      errors.QualityManagementType = "Please select Quality Management Type!";
    }

    if (values.QualityManagementType === "1") {
      if (values.IsoCertifyingBody === "") {
        errors.IsoCertifyingBody = "This field is required!";
      }
    } else {
      values.IsoCertifyingBody = "";
    }

    if (!values.ValidityFrom) {
      errors.ValidityFrom = "Validity from is required!";
    }

    if (!values.ValidityTo) {
      errors.ValidityTo = "Validity to is required!";
    }

    if (values.QualityManagementType === "3") {
      if (
        values.PhilhealthAccreditation === "" ||
        values.PhilhealthAccreditation === "X"
      ) {
        errors.PhilhealthAccreditation =
          "Please select Philhealth Accreditation!";
      }
    }

    return errors;
  }

  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());
    var token = localStorage.getItem("token_id");
    var headers = {
      Authorization: "Bearer " + token,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    const valuestosave = {
      ...values,
      userFullname: userFullname,
      username: username,
      Id: Id,
    };

    axios
      .post(serverUrl + "/api/savequalitymanagement", valuestosave, headers)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());

              props.history.push("/qualitymanagements");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div style={{ marginTop: 15 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" align="center">
              Quality Management
            </Typography>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                href="#"
                onClick={() => handleClick("/home")}
              >
                Home
              </Link>
              <Link
                color="inherit"
                href="#"
                onClick={() => handleClick("/qualitymanagements")}
              >
                Quality Management
              </Link>
            </Breadcrumbs>
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  <Field
                    type="text"
                    label="Reporting Year"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    values={values.ReportingYear}
                    required={true}
                    name="ReportingYear"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {reportingYears.map((option) => (
                      <MenuItem
                        key={option.ReportingYear}
                        value={option.ReportingYear}
                      >
                        {option.ReportingYear}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="text"
                    label="Quality Management Type"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    required
                    values={values.QualityManagementType}
                    name="QualityManagementType"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {qualitymanagementtype.map((option) => (
                      <MenuItem key={option.qmcode} value={option.qmcode}>
                        {option.qmdesc}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="text"
                    label="ISO Certifying Body"
                    name="IsoCertifyingBody"
                    placeholder="ISO Certifying Body"
                    component={TextField}
                    // variant="outlined"
                    disabled={values.QualityManagementType !== "1"}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.IsoCertifyingBody !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Description"
                    name="Description"
                    placeholder="Description"
                    component={TextField}
                    // variant="outlined"
                    // disabled={values.QualityManagementType != "1"}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Description !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="PhilHealth Accreditation"
                    select
                    margin="dense"
                    disabled={values.QualityManagementType !== "3"}
                    component={TextField}
                    fullWidth
                    required
                    values={values.PhilhealthAccreditation}
                    name="PhilhealthAccreditation"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {philhealthAccreditation.map((option) => (
                      <MenuItem key={option.qmpcode} value={option.qmpcode}>
                        {option.qmpdesc}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="date"
                    label="Validity From"
                    name="ValidityFrom"
                    component={TextField}
                    // variant="outlined"
                    value={values.ValidityFrom}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    type="date"
                    label="Validity To"
                    name="ValidityTo"
                    component={TextField}
                    // variant="outlined"
                    value={values.ValidityTo}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    fullWidth
                  />
                  Delete{" "}
                  <Field
                    label="Delete"
                    name="Deleted"
                    id="Delete"
                    color="primary"
                    disabled={Id === ""}
                    component={CheckboxWithLabel}
                  />
                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </div>
  );
};

export default withRouter(QualityManagement);

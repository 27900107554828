export const loggedin = (userdetails) => {
  return {
    type: "LOGGED_IN",
    payload: userdetails,
  };
};

export const loggedout = () => {
  return {
    type: "LOGGED_OUT",
  };
};

export const setSettings = (settings) => {
  return {
    type: "SET_SETTINGS",
    payload: settings,
  };
};

export const setloading = () => {
  return {
    type: "SET_LOADING",
  };
};

export const notloading = () => {
  return {
    type: "NOT_LOADING",
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";

import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { Button, Grid, Paper, Typography, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  disabledtextbox: {
    fontSize: 20,
    color: "blue",
    fontWeight: "bold",
  },
}));
const Revenues = (props) => {
  const dispatch = useDispatch();
  const [details, setdetails] = useState([]);
  const [reportingYears, setreportingYears] = useState([]);

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  const userFullname = u.userFullname;

  const facilitycode = u.facilitycode;

  const [firstLoad, setfirstLoad] = useState(true);
  const [isEdit, setisEdit] = useState(details.length <= 0 ? false : true);
  const [oldReportingYear, setoldReportingYear] = useState("");
  const [currentReportingYear, setcurrentReportingYear] = useState("");

  useEffect(() => {
    if (firstLoad) {
      handlegetReportingYear();

      setfirstLoad(false);
    }
  }, [firstLoad]);

  const classes = useStyles();
  function handleInitialValues() {
    return {
      ReportingYear: isEdit ? details.ReportingYear : currentReportingYear,
      amountfromdoh: isEdit ? details.amountfromdoh : 0,
      amountfromlgu: isEdit ? details.amountfromlgu : 0,
      amountfromdonor: isEdit ? details.amountfromdonor : 0,
      amountfromprivateorg: isEdit ? details.amountfromprivateorg : 0,
      amountfromphilhealth: isEdit ? details.amountfromphilhealth : 0,
      amountfrompatient: isEdit ? details.amountfrompatient : 0,
      amountfromreimbursement: isEdit ? details.amountfromreimbursement : 0,
      amountfromothersources: isEdit ? details.amountfromothersources : 0,
      grandtotal: isEdit ? details.grandtotal : 0,
    };
  }

  const handlegetReportingYear = () => {
    axios
      .get(serverUrl + "/api/getreportingyear")
      .then((res) => {
        console.log(res);
        setreportingYears(res.data);
        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  function validateFields(values) {
    const errors: Partial<Values> = {};

    if (values.ReportingYear === "" || values.ReportingYear === null) {
      errors.ReportingYear = "Please select reporting year!";
    }

    if (values.amountfromdoh === "" || !values.amountfromdoh)
      values.amountfromdoh = 0;
    if (values.amountfromlgu === "" || !values.amountfromlgu)
      values.amountfromlgu = 0;
    if (values.amountfromdonor === "" || !values.amountfromdonor)
      values.amountfromdonor = 0;

    if (values.amountfromprivateorg === "" || !values.amountfromprivateorg)
      values.amountfromprivateorg = 0;
    if (values.amountfromphilhealth === "" || !values.amountfromphilhealth)
      values.amountfromphilhealth = 0;
    if (values.amountfrompatient === "" || !values.amountfrompatient)
      values.amountfrompatient = 0;
    if (
      values.amountfromreimbursement === "" ||
      !values.amountfromreimbursement
    )
      values.amountfromreimbursement = 0;
    if (values.amountfromothersources === "" || !values.amountfromothersources)
      values.amountfromothersources = 0;

    values.grandtotal =
      values.amountfromdoh +
      values.amountfromlgu +
      values.amountfromdonor +
      values.amountfromprivateorg +
      values.amountfromphilhealth +
      values.amountfrompatient +
      values.amountfromreimbursement +
      values.amountfromothersources;
    return errors;
  }

  function handleReportingYearChange(values) {
    setcurrentReportingYear(values.ReportingYear);
    if (values.ReportingYear === "") return;
    if (values.ReportingYear === oldReportingYear) return;
    setoldReportingYear(values.ReportingYear);

    let q = "";

    q =
      "select * from tblRevenues where reportingyear='" +
      values.ReportingYear +
      "' and facilitycode='" +
      facilitycode +
      "'";

    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: q,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          console.log(res.data[0]);
          setdetails(res.data[0]);

          setisEdit(true);
        } else {
          setisEdit(false);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  }

  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());
    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const valuestosave = {
      ...values,
      UserName: userFullname,
      FacilityCode: facilitycode,
    };

    axios
      .post(serverUrl + "/api/saverevenues", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());

              // props.history.push("/");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" align="center">
              Revenues (in Pesos)
            </Typography>
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  <Field
                    type="text"
                    label="Reporting Year"
                    select
                    margin="dense"
                    component={TextField}
                    onChange={handleReportingYearChange(values)}
                    fullWidth
                    values={values.ReportingYear || ""}
                    required={true}
                    name="ReportingYear"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {reportingYears.map((option) => (
                      <MenuItem
                        key={option.ReportingYear}
                        value={option.ReportingYear}
                      >
                        {option.ReportingYear}
                      </MenuItem>
                    ))}
                  </Field>

                  <Field
                    type="number"
                    label="Total amount of money received from the Department of Health"
                    name="amountfromdoh"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountfromdoh !== "",
                    }}
                  />
                  <Field
                    type="number"
                    label="Total amount of money received from the local government"
                    name="amountfromlgu"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountfromlgu !== "",
                    }}
                  />

                  <Field
                    type="number"
                    label="Total amount of money received from donor agencies (for example JICA, USAID, and others)"
                    name="amountfromdonor"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountfromdonor !== "",
                    }}
                  />

                  <Field
                    type="number"
                    label="Total amount of money received from private organizations (donations from businesses, NGOs, etc.)"
                    name="amountfromprivateorg"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountfromprivateorg !== "",
                    }}
                  />

                  <Field
                    type="number"
                    label="Total amount of money received from Phil Health"
                    name="amountfromphilhealth"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountfromphilhealth !== "",
                    }}
                  />
                  <Field
                    type="number"
                    label="Total amount of money received from direct patient/out-of-pocket charges/fees"
                    name="amountfrompatient"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountfrompatient !== "",
                    }}
                  />

                  <Field
                    type="number"
                    label="Total amount of money received from reimbursement from private insurance/HMOs"
                    name="amountfromreimbursement"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountfromreimbursement !== "",
                    }}
                  />

                  <Field
                    type="number"
                    label="Total amount of money received from other sources (PAGCOR, PCSO, etc.)"
                    name="amountfromothersources"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountfromothersources !== "",
                    }}
                  />

                  <div style={{ fontWeight: "bold", fontSize: 20 }}>
                    {"GRAND TOTAL"}
                  </div>
                  <div className="disabledtextbox">
                    <Typography
                      variant="h5"
                      component="h3"
                      style={{ fontWeight: "bold", fontSize: 20 }}
                    >
                      {values.grandtotal}
                    </Typography>
                  </div>

                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Revenues);

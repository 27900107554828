/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading, loggedout } from "../../actions";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
const ChangePassword = (props) => {
  const dispatch = useDispatch();

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  const userFullname = u.userFullname;

  const [details, setdetails] = useState([]);
  const Id = props.location.state.id;
  const [firstLoad, setfirstLoad] = useState(true);
  const requirePassword = props.location.state.requirePassword;
  const [isEdit, setisEdit] = useState(false);

  useEffect(() => {
    if (firstLoad) {
      if (Id !== "") {
        setisEdit(true);
        handleGetUserDetails();
        setfirstLoad(false);
      }
    }
  }, [firstLoad]);

  /*  const [values, setValues] = React.useState({
    name: "Cat in the Hat",
    age: "",
    multiline: "Controlled",
    currency: "EUR"
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
 */
  const classes = useStyles();
  function handleInitialValues() {
    return {
      UserName: isEdit ? details.UserName : "",
      FullName: isEdit ? details.FullName : "",
      Password: null,
      ConfirmPassword: null,
      OldPassword: null,
    };
  }

  const handleGetUserDetails = () => {
    dispatch(setloading());

    axios
      .get(serverUrl + "/api/getuserdetails/", {
        params: {
          username: "",
          id: Id,
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          setdetails(res.data.user);
          console.log(details);
          dispatch(notloading());
        } else {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function handleClick(x) {
    props.history.push(x);
  }

  function validateFields(values) {
    const errors: Partial<Values> = {};

    if (!values.UserName) {
      errors.UserName = "Username is required!";
    }

    if (!values.FullName) {
      errors.FullName = "Fullname is required!";
    }

    if (!values.Password) {
      errors.Password = "Password is required!";
    } else {
      if (values.Password.length < 5) {
        errors.Password = "Password length must be 5 characters or more!";
      }
    }

    if (!values.ConfirmPassword) {
      errors.ConfirmPassword = "Please confirm the password!";
    }
    if (values.Password !== values.ConfirmPassword) {
      errors.ConfirmPassword = "Passwords do not match!";
    }

    if (requirePassword === true) {
      if (!values.OldPassword) {
        errors.OldPassword = "Old Password is required!";
      }
    }

    return errors;
  }
  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());

    const valuestosave = {
      ...values,
      userFullname: userFullname,
      Id: Id,
    };

    axios
      .post(serverUrl + "/api/changepassword", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());
              if (requirePassword === false) {
                props.history.push("/users");
              } else {
                dispatch(loggedout());
                localStorage.removeItem("userdetails");
                props.history.push("/login");
              }
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.root}>
            {!requirePassword ? (
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link
                  color="inherit"
                  href="#"
                  onClick={() => handleClick("/logout")}
                >
                  Home
                </Link>
                <Link
                  color="inherit"
                  href="#"
                  onClick={() => handleClick("/users")}
                >
                  Users
                </Link>
              </Breadcrumbs>
            ) : (
              ""
            )}

            <Typography variant="h5" component="h3" align="center">
              User Profile
            </Typography>
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  <Field
                    name="UserName"
                    type="text"
                    label="Username"
                    component={TextField}
                    //  variant="outlined"
                    margin="dense"
                    fullWidth
                    autoFocus
                    disabled={true}
                    InputLabelProps={{
                      shrink: values.UserName !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Fullname"
                    name="FullName"
                    placeholder="Fullname"
                    disabled={true}
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.FullName !== "",
                    }}
                  />
                  <Field
                    type="password"
                    label="Old Password"
                    name="OldPassword"
                    placeholder="Password"
                    component={TextField}
                    disabled={!requirePassword}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    type="password"
                    label="New Password"
                    name="Password"
                    placeholder="Password"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Password !== "",
                    }}
                  />
                  <Field
                    type="password"
                    label="Confirm New Password"
                    name="ConfirmPassword"
                    placeholder="Confirm Password"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.ConfirmPassword !== "",
                    }}
                  />

                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </div>
  );
};

export default withRouter(ChangePassword);

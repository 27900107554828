import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loggedin, setloading, notloading } from "../actions";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Cookie from "js-cookie";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const LogIn = (props) => {
  const dispatch = useDispatch();
  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityName = s.facilityName;

  useEffect(() => {}, []);
  const classes = useStyles();

  const handleInitialValues = () => {
    return {
      UserName: "",
      FullName: "",
      EmailAddress: "",
      ContactNos: "",
      Password: "",
      ConfirmPassword: "",
    };
  };

  const validateFields = (values) => {
    const errors: Partial<Values> = {};

    if (!values.UserName) {
      errors.UserName = "Username is required!";
    }

    if (!values.Password) {
      errors.Password = "Password is required!";
    }

    return errors;
  };

  const submitForm = (values, { setSubmitting, resetForm }) => {
    dispatch(setloading());
    const valuestosave = {
      ...values,
    };

    axios
      .post(serverUrl + "/api/login", valuestosave)
      .then((res) => {
        if (res.data.status === 1) {
          Cookie.set("serverUrl", serverUrl, "/");
          Cookie.set("facilityName", res.data.facilityname, "/");
          Cookie.set("facilitycode", res.data.facilitycode, "/");
          Cookie.set("username", res.data.username, "/");
          Cookie.set("userFullname", res.data.userFullname, "/");
          const userdetails = {
            username: res.data.username,
            userFullname: res.data.userFullname,
            isLogged: true,
            userid: res.data.userid,
            token: res.data.token,
            facilitycode: res.data.facilitycode,
            facilityName: res.data.facilityname,
          };
          localStorage.setItem("token_id", res.data.token_id);
          localStorage.setItem("serverUrl", serverUrl);
          localStorage.setItem("facilityCode", res.data.facilitycode);
          dispatch(notloading());
          dispatch(loggedin(userdetails));
          //   localStorage.setItem("userdetails", JSON.stringify(userdetails));
          var userdetailsEncrypted = CryptoAES.encrypt(
            JSON.stringify(userdetails),
            "cbs211&213&215"
          );
          localStorage.setItem("userdetails", userdetailsEncrypted);
          Swal.fire({
            title: facilityName,
            text: "User successfully validated!",
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              props.history.push("/home");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityName,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(notloading());
        Swal.fire({
          title: sessionStorage.getItem("facilityname"),
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" align="center">
              Welcome to {facilityName}
            </Typography>
            <Formik
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Field
                    name="UserName"
                    type="text"
                    label="Username"
                    component={TextField}
                    //  variant="outlined"
                    margin="dense"
                    fullWidth
                    autoFocus
                  />

                  <Field
                    type="password"
                    label="Password"
                    name="Password"
                    placeholder="Password"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                  />

                  {/*       {isSubmitting && (
                    <LinearProgress className={classes.progress} />
                  )} */}
                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        type="submit"
                      >
                        <SendIcon className={classes.leftIcon} />
                        Login
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </div>
  );
};

export default withRouter(LogIn);

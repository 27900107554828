/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import Cookies from "js-cookie";

import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
const SubmitReport2 = (props) => {
  const dispatch = useDispatch();

  const [serverUrl] = useState(Cookies.get("serverUrl"));
  const [facilityname] = useState(Cookies.get("facilityName"));
  const [ReportingYear] = useState(props.location.state.ReportingYear);

  useEffect(() => {}, []);

  const classes = useStyles();
  function handleInitialValues() {
    return {
      ReportedBy: "",
      Designation: "",
      Section: "",
      Department: "",
    };
  }
  function validateFields(values) {
    const errors: Partial<Values> = {};

    if (!values.ReportedBy) {
      errors.ReportedBy = "Reported by is required!";
    }

    if (!values.Designation) {
      errors.Designation = "Designation is required!";
    }

    if (!values.Section) {
      errors.Section = "Section is required!";
    }
    if (!values.Department) {
      errors.Department = "Department is required!";
    }

    return errors;
  }

  const submitForm = async (values, { setSubmitting, resetForm }) => {
    const valuestosave = {
      ...values,
      FacilityCode: Cookies.get("facilitycode"),
      ReportingYear: ReportingYear,
      SubmittedBy: Cookies.get("userFullname"),
    };
    console.log(valuestosave);
    dispatch(setloading());

    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    await axios
      .post(serverUrl + "/api/submitreport2", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());
              props.history.push("/patients");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  };
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={handleInitialValues()}
        validate={(values) => validateFields(values)}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          submitForm(values, { setSubmitting, resetForm })
        }
        render={({
          submitForm,
          isSubmitting,
          values,
          setFieldValue,
          touched,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={12}>
                <Paper className={classes.root}>
                  <Typography variant="h6" component="h6" align="center">
                    Submit/Finalized Report
                  </Typography>

                  <Field
                    type="text"
                    label="Reported By"
                    name="ReportedBy"
                    placeholder="Reported By"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.ReportedBy !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Designation"
                    name="Designation"
                    placeholder="Designation"
                    component={TextField}
                    //  variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Designation !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Section"
                    name="Section"
                    placeholder="Section"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Section !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Department"
                    name="Department"
                    placeholder="Department"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Department !== "",
                    }}
                  />

                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    </div>
  );
};

export default withRouter(SubmitReport2);

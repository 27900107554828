import React from "react";
import { withRouter } from "react-router-dom";
import Test2 from "./Test2";

const Test = (props) => {
  return (
    <div>
      <Test2 value={"Allan Fabular"} valuex={"xxxxxxxxxxx"} />
    </div>
  );
};

export default withRouter(Test);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { Button, Grid, Paper, Typography, MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 10,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  disabledtextbox: {
    fontSize: 20,
    color: "blue",
    fontWeight: "bold",
  },
}));
const Expenses = (props) => {
  const dispatch = useDispatch();
  const [details, setdetails] = useState([]);
  const [reportingYears, setreportingYears] = useState([]);

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  const userFullname = u.userFullname;

  const facilitycode = u.facilitycode;

  const [firstLoad, setfirstLoad] = useState(true);
  const [isEdit, setisEdit] = useState(details.length <= 0 ? false : true);
  const [oldReportingYear, setoldReportingYear] = useState("");
  const [currentReportingYear, setcurrentReportingYear] = useState("");

  useEffect(() => {
    if (firstLoad) {
      handlegetReportingYear();

      setfirstLoad(false);
    }
  }, [firstLoad]);

  const classes = useStyles();
  function handleInitialValues() {
    return {
      ReportingYear: isEdit ? details.ReportingYear : currentReportingYear,
      salarieswages: isEdit ? details.salarieswages : 0,
      employeebenefits: isEdit ? details.employeebenefits : 0,
      allowances: isEdit ? details.allowances : 0,
      totalps: isEdit ? details.totalps : 0,
      totalamountmedicine: isEdit ? details.totalamountmedicine : 0,
      totalamountmedicalsupplies: isEdit
        ? details.totalamountmedicalsupplies
        : 0,
      totalamountutilities: isEdit ? details.totalamountutilities : 0,
      totalamountnonmedicalservice: isEdit
        ? details.totalamountnonmedicalservice
        : 0,
      totalmooe: isEdit ? details.totalmooe : 0,
      amountinfrastructure: isEdit ? details.amountinfrastructure : 0,
      amountequipment: isEdit ? details.amountequipment : 0,
      totalco: isEdit ? details.totalco : 0,
      grandtotal: isEdit ? details.grandtotal : 0,
    };
  }

  const handlegetReportingYear = () => {
    axios
      .get(serverUrl + "/api/getreportingyear")
      .then((res) => {
        console.log(res);
        setreportingYears(res.data);
        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  function validateFields(values) {
    const errors: Partial<Values> = {};

    if (values.ReportingYear === "" || values.ReportingYear === null) {
      errors.ReportingYear = "Please select reporting year!";
    }

    if (values.salarieswages === "" || !values.salarieswages)
      values.salarieswages = 0;
    if (values.employeebenefits === "" || !values.employeebenefits)
      values.employeebenefits = 0;
    if (values.allowances === "" || !values.allowances) values.allowances = 0;

    const totalps =
      values.salarieswages + values.employeebenefits + values.allowances;
    values.totalps = totalps;

    if (!values.totalamountmedicine) values.totalamountmedicine = 0;
    if (!values.totalamountmedicalsupplies)
      values.totalamountmedicalsupplies = 0;
    if (!values.totalamountutilities) values.totalamountutilities = 0;
    if (!values.totalamountnonmedicalservice)
      values.totalamountnonmedicalservice = 0;

    values.totalmooe =
      values.totalamountmedicine +
      values.totalamountmedicalsupplies +
      values.totalamountutilities +
      values.totalamountnonmedicalservice;

    if (!values.amountinfrastructure) values.amountinfrastructure = 0;
    if (!values.amountequipment) values.amountequipment = 0;

    values.totalco = values.amountinfrastructure + values.amountequipment;

    values.grandtotal = values.totalps + values.totalmooe + values.totalco;
    return errors;
  }

  function handleReportingYearChange(values) {
    setcurrentReportingYear(values.ReportingYear);
    if (values.ReportingYear === "") return;
    if (values.ReportingYear === oldReportingYear) return;
    setoldReportingYear(values.ReportingYear);

    let q = "";

    q =
      "select * from tblExpenses where reportingyear='" +
      values.ReportingYear +
      "' and facilitycode='" +
      facilitycode +
      "'";

    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: q,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          console.log(res.data[0]);
          setdetails(res.data[0]);

          setisEdit(true);
        } else {
          setisEdit(false);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  }

  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());

    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const valuestosave = {
      ...values,
      UserName: userFullname,
      FacilityCode: facilitycode,
    };

    axios
      .post(serverUrl + "/api/saveexpenses", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());

              // props.history.push("/");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" align="center">
              Expenses (in Pesos)
            </Typography>
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  <Field
                    type="text"
                    label="Reporting Year"
                    select
                    margin="dense"
                    component={TextField}
                    onChange={handleReportingYearChange(values)}
                    fullWidth
                    values={values.ReportingYear || ""}
                    required={true}
                    name="ReportingYear"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {reportingYears.map((option) => (
                      <MenuItem
                        key={option.ReportingYear}
                        value={option.ReportingYear}
                      >
                        {option.ReportingYear}
                      </MenuItem>
                    ))}
                  </Field>

                  <Field
                    type="number"
                    label="Amount spent on personnel salaries and wages"
                    name="salarieswages"
                    placeholder="Amount spent on personnel salaries and wages"
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.salarieswages !== "",
                    }}
                  />
                  <Field
                    type="number"
                    label="Amount spent on benefits for employees "
                    name="employeebenefits"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.employeebenefits !== "",
                    }}
                  />

                  <Field
                    type="number"
                    label="Allowances provided to employees at this facility (Allowances are in addition to wages/salaries)"
                    name="allowances"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.allowances !== "",
                    }}
                  />
                  {/*  <Field
                    type="number"
                    label="TOTAL amount spent on all personnel including wages, salaries, benefits and allowances for last year (PS)"
                    name="totalps"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    disabled={true}
                    fullWidth
                    InputProps={{
                      className: classes.disabledtextbox
                    }}
                    InputLabelProps={{
                      shrink: values.totalps !== ""
                    }}
                  /> */}
                  <div>
                    {
                      "TOTAL amount spent on all personnel including wages, salaries, benefits and allowances for last year (PS)"
                    }
                  </div>
                  <div className="disabledtextbox">
                    <Typography variant="h5" component="h3">
                      {values.totalps}
                    </Typography>
                  </div>

                  <Field
                    type="number"
                    label="Total amount spent on medicines"
                    name="totalamountmedicine"
                    placeholder="Total amount spent on medicines"
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.totalamountmedicine !== "",
                    }}
                  />

                  <Field
                    type="number"
                    label="Total amount spent on medical supplies (i.e. syringe, gauze, etc.; exclude pharmaceuticals)"
                    name="totalamountmedicalsupplies"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.totalamountmedicine !== "",
                    }}
                  />
                  <Field
                    type="number"
                    label="Total amount spent on utilities"
                    name="totalamountutilities"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.totalamountmedicine !== "",
                    }}
                  />
                  <Field
                    type="number"
                    label="Total amount spent on non-medical services (For example: security, food service, laundry, waste management)"
                    name="totalamountnonmedicalservice"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.totalamountmedicine !== "",
                    }}
                  />
                  {/*            <Field
                    type="number"
                    label="TOTAL amount spent on maintenance and other operating expenditures (MOOE)"
                    name="totalmooe"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    disabled={true}
                    InputProps={{
                      className: classes.disabledtextbox
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  /> */}

                  <div>
                    {
                      "TOTAL amount spent on all personnel including wages, salaries, benefits and allowances for last year (PS)"
                    }
                  </div>
                  <div className="disabledtextbox">
                    <Typography variant="h5" component="h3">
                      {values.totalmooe}
                    </Typography>
                  </div>

                  <Field
                    type="number"
                    label="Amount spent on infrastructure (i.e., new hospital wing, installation of ramps)"
                    name="amountinfrastructure"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountequipment !== "",
                    }}
                  />

                  <Field
                    type="number"
                    label="Amount spent on equipment (i.e. x-ray machine, CT scan)"
                    name="amountequipment"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.amountequipment !== "",
                    }}
                  />

                  {/*  <Field
                    type="number"
                    label="TOTAL amount spent on capital outlay (CO)"
                    name="totalco"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    disabled={true}
                    fullWidth
                    InputLabelProps={{
                      shrink: values.totalco !== ""
                    }}
                    InputProps={{
                      className: classes.disabledtextbox
                    }}
                  /> */}
                  {/*                  <Field
                    type="number"
                    label="TOTAL amount spent on capital outlay (CO)"
                    name="totalco"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    disabled={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                  /> */}

                  <div>{"TOTAL amount spent on capital outlay (CO)"}</div>
                  <div className="disabledtextbox">
                    <Typography variant="h5" component="h3">
                      {values.totalco}
                    </Typography>
                  </div>

                  {/*         <Field
                    type="number"
                    label="GRAND TOTAL"
                    name="grandtotal"
                    placeholder=""
                    component={TextField}
                    margin="dense"
                    fullWidth
                    disabled={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      className: classes.disabledtextbox
                    }}
                  /> */}

                  <div style={{ fontWeight: "bold", fontSize: 20 }}>
                    {"GRAND TOTAL"}
                  </div>
                  <div className="disabledtextbox">
                    <Typography
                      variant="h5"
                      component="h3"
                      style={{ fontWeight: "bold", fontSize: 20 }}
                    >
                      {values.grandtotal}
                    </Typography>
                  </div>

                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Expenses);

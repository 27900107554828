/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import {
  faUserEdit,
  faUserPlus,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  marginr: {
    marginRight: 4,
  },
}));
const Users = (props) => {
  const dispatch = useDispatch();
  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityName = s.facilityName;

  const [rows, setRows] = useState([]);

  const [reloadData, setreloadData] = useState(true);
  const [Id] = useState("");
  useEffect(() => {
    if (reloadData) {
      fetchData();
      setreloadData(false);
    }
  }, [reloadData]);
  const classes = useStyles();

  const handleNew = () => {
    props.history.push({
      pathname: "/userprofile",
      state: {
        id: Id,
        requirePassword: true,
        returnUrl: "/users",
        enableAdmin: true,
      },
    });
  };

  const columns = [
    {
      name: "Id",
      label: "ID",
      options: {
        display: "false",
        filter: false,
      },
    },
    {
      name: "UserName",
      display: true,
      label: "Username",
    },
    {
      name: "FullName",
      display: true,
      label: "Fullname",
    },
    {
      name: "AddedBy",
      display: true,
      label: "Added By",
      options: {
        sort: false,
      },
    },
    {
      name: "FacilityCode",
      display: true,
      label: "Facility Code",
      options: {
        sort: false,
      },
    },
  ];

  const fetchData = () => {
    dispatch(setloading());
    axios
      .get(serverUrl + "/api/getlistofusers")
      .then((res) => {
        setRows(res.data.rows);
        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityName,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  const onTableChange = (action, tableState) => {
    console.log(action);
  };

  const onColumnSortChange = (changedColumn, direction) => {
    console.log(changedColumn);
    console.log(direction);
  };
  // customToolbarSelect: () => {},

  const customToolbarSelect = (selectedRows, displayData, setSelectedRows) => {
    const newSelectedRow = selectedRows.data[selectedRows.data.length - 1];
    const selectedData = displayData[newSelectedRow.index];
    const Id = selectedData.data[0];

    const handleEdit = () => {
      props.history.push({
        pathname: "/userprofile",
        state: {
          id: Id,
          requirePassword: false,
          returnUrl: "/users",
          enableAdmin: true,
        },
      });
    };

    const handleChangePassword = () => {
      props.history.push({
        pathname: "/changepassword",
        state: { id: Id, requirePassword: false, returnUrl: "/users" },
      });
    };

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.margin}
          onClick={handleEdit}
        >
          <FontAwesomeIcon icon={faUserEdit} className={classes.marginr} />
          Edit
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.margin}
          onClick={handleChangePassword}
        >
          <FontAwesomeIcon icon={faKey} className={classes.marginr} />
          Change Password
        </Button>
      </div>
    );
  };

  const options = {
    filterType: "dropdown",
    selectableRows: "single",
    selectableRowsOnClick: true,
    serverSide: true,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
      customToolbarSelect(selectedRows, displayData, setSelectedRows),
    responsive: "scrollMaxHeight",
    onTableChange: (action, tableState) => onTableChange(action, tableState),
    onColumnSortChange: (changedColumn, direction) =>
      onColumnSortChange(changedColumn, direction),
    fixedHeader: true,
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.margin}
        onClick={handleNew}
      >
        <FontAwesomeIcon icon={faUserPlus} className={classes.marginr} />
        New
      </Button>

      <MUIDataTable
        title={"User list"}
        data={rows}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default withRouter(Users);

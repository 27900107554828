import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Cookie from "js-cookie";
import axios from "axios";
const Home = (props) => {
  const handleCheckVersion = async () => {
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    await axios
      .get(Cookie.get("serverUrl") + "/api/GetListOfRecordsNew", {
        params: {
          sp: "udp_GetSettings",
          parameters: {
            code: "version",
          },
        },
      })
      .then((res) => {
        const x = res.data[0];

        if (x.Valuex !== Cookie.get("version")) {
          Cookie.set("version", x.Valuex, "/");
          window.location.reload(true);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    handleCheckVersion();
  });
  return (
    <div>
      <h1>Home</h1>
    </div>
  );
};

export default withRouter(Home);

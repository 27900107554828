/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import Diagnosis from "./Diagnosis";
import SurgicalOperations from "./SurgicalOperations";
import axios from "axios";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  MenuItem,
} from "@material-ui/core";
import { TextField, Checkbox } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
const ConditionOnDischarge = (props) => {
  const dispatch = useDispatch();
  const CaseId = props.location.state.CaseId;
  const PatientName = props.location.state.PatientName;
  const WithInfection =
    props.location.state.WithInfection === null
      ? false
      : props.location.state.WithInfection;
  const ConditionOnDischarge =
    props.location.state.ConditionOnDischarge === ""
      ? "0"
      : props.location.state.ConditionOnDischarge;

  const VentilatorDays =
    props.location.state.VentilatorDays === ""
      ? "0"
      : props.location.state.VentilatorDays;

  const CentralLine =
    props.location.state.CentralLine === ""
      ? "0"
      : props.location.state.CentralLine;

  const CatheterDays =
    props.location.state.CatheterDays === ""
      ? "0"
      : props.location.state.CatheterDays;

  const CleanProcedure =
    props.location.state.CleanProcedure === ""
      ? "0"
      : props.location.state.CleanProcedure;

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));

  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  const userFullname = u.userFullname;
  const [firstLoad, setfirstLoad] = useState(true);
  const requirePassword = props.location.state.requirePassword;
  const [isEdit, setisEdit] = useState(false);

  useEffect(() => {
    if (firstLoad) {
      if (CaseId !== "") {
        //  handleGetConditionOnDischarge();
        setisEdit(true);
        setfirstLoad(false);
      }
    }
  }, []);

  const conditionondischarge = [
    {
      value: "X",
      label: "Please Select",
    },
    {
      value: "A",
      label: "Absconded",
    },
    {
      value: "DG48",
      label: "Died Greater Than 48 hrs.",
    },
    {
      value: "DL48",
      label: "Died Less Than 48 hrs.",
    },
    {
      value: "HMA",
      label: "Home Against Medical Advice",
    },
    {
      value: "RI",
      label: "Recovered/Improved",
    },
    {
      value: "T",
      label: "Transferred",
    },
    {
      value: "U",
      label: "Unimproved",
    },
  ];
  const classes = useStyles();
  function handleInitialValues() {
    return {
      ConditionOnDischarge: isEdit ? ConditionOnDischarge : "X",
      VentilatorDays: isEdit ? VentilatorDays : "0",
      CentralLine: isEdit ? CentralLine : "0",
      CatheterDays: isEdit ? CatheterDays : "0",
      CleanProcedure: isEdit ? CleanProcedure : "0",
      WithInfection: isEdit ? WithInfection : false,
    };
  }

  function handleClick(x) {
    props.history.push(x);
  }

  function validateFields(values) {
    const errors: Partial<Values> = {};

    if (!values.ConditionOnDischarge) {
      errors.ConditionOnDischarge = "Username is required!";
    }

    if (values.ConditionOnDischarge === "X") {
      errors.FullName = "Please select condition on discharge!";
    }

    if (values.VentilatorDays === "") values.VentilatorDays = "0";
    if (values.CentralLine === "") values.CentralLine = "0";
    if (values.CatheterDays === "") values.CatheterDays = "0";
    if (values.CleanProcedure === "") values.CleanProcedure = "0";

    return errors;
  }
  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());

    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const valuestosave = {
      ...values,
      userFullname: userFullname,
      CaseId: CaseId,
    };

    axios
      .post(serverUrl + "/api/saveconditionondischarge", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: "Successfully Saved!",
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());
              //  props.history.push("/patients");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={12}>
          <Paper className={classes.root}>
            {!requirePassword ? (
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link
                  color="inherit"
                  href="#"
                  onClick={() => handleClick("/home")}
                >
                  Home
                </Link>
                <Link
                  color="inherit"
                  href="#"
                  onClick={() => handleClick("/patients")}
                >
                  Patients
                </Link>
              </Breadcrumbs>
            ) : (
              ""
            )}

            <Typography variant="h6" component="h6" align="center">
              Condition on Discharge for patient {PatientName}
            </Typography>
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validate={(values) => validateFields(values)}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                submitForm(values, { setSubmitting, resetForm })
              }
              render={({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                touched,
              }) => (
                <Form>
                  <Field
                    type="text"
                    label="Condition On Discharge"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    name="ConditionOnDischarge"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {conditionondischarge.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  Check if patient has an infection{" "}
                  <Field
                    label="With Infection"
                    name="WithInfection"
                    id="WithInfection"
                    color="primary"
                    component={Checkbox}
                    type="checkbox"
                    value={values.WithInfection}
                  />
                  <Paper
                    className={classes.root}
                    style={{ marginBottom: 10, marginTop: 10 }}
                  >
                    <Typography variant="h6" component="h6" align="center">
                      Healthcare Associated Infections
                    </Typography>
                    <Field
                      type="number"
                      label="Ventilator Days"
                      name="VentilatorDays"
                      placeholder="Ventilator Days"
                      component={TextField}
                      // variant="outlined"
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.VentilatorDays !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Central Line"
                      name="CentralLine"
                      placeholder="Central Line"
                      component={TextField}
                      // variant="outlined"
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.CentralLine !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="Catheter Days"
                      name="CatheterDays"
                      placeholder="Catheter Days"
                      component={TextField}
                      // variant="outlined"
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.CatheterDays !== "",
                      }}
                    />
                    <Field
                      type="number"
                      label="CleanProcedure"
                      name="CleanProcedure"
                      placeholder="Clean Procedure"
                      component={TextField}
                      // variant="outlined"
                      margin="dense"
                      fullWidth
                      InputLabelProps={{
                        shrink: values.CleanProcedure !== "",
                      }}
                    />
                  </Paper>
                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Diagnosis CaseId={CaseId} PatientName={PatientName} />
        </Grid>

        <Grid item xs={12} sm={12}>
          <SurgicalOperations CaseId={CaseId} PatientName={PatientName} />
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(ConditionOnDischarge);

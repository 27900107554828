import loggedReducer from "./isLogged";
import settings from "./settings";
import loading from "./isLoading";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  isLogged: loggedReducer,
  settings: settings,
  loading: loading
});

export default allReducers;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { loggedout, setSettings, loggedin } from "./actions";
import { withRouter } from "react-router-dom";
import Routes from "./components/Routes";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import "./App.css";
import clsx from "clsx";
import Swal from "sweetalert2";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import {
  CssBaseline,
  Typography,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import {
  faUser,
  faHome,
  faFolder,
  faFileMedical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import { faLaptopMedical } from "@fortawesome/free-solid-svg-icons";

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  nested1: {
    paddingLeft: theme.spacing(5),
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
}));

const App = (props) => {
  const [isLogged, setisLogged] = React.useState(false);
  const loading = useSelector((state) => state.loading);

  const [username, setusername] = React.useState("");
  const [userid, setuserid] = React.useState("");

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openUsers, setOpenUsers] = React.useState(false);
  const [openFile, setOpenFile] = React.useState(false);
  const [openSettings, setopenSettings] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [firstLoad, setfirstLoad] = React.useState(true);

  const [serverUrl, setserverUrl] = useState("");
  const [facilityName, setfacilityName] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    var userdetails = localStorage.getItem("userdetails");
    var token = localStorage.getItem("token_id");

    axios.defaults.baseURL = serverUrl;
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    if (userdetails !== null) {
      var udetails = CryptoAES.decrypt(
        userdetails.toString(),
        "cbs211&213&215"
      );
      var u = JSON.parse(udetails.toString(CryptoENC));
      setfacilityName(u.facilityName);
      setusername(u.username);
      setuserid(u.userid);
      setisLogged(true);
    } else {
      setisLogged(false);
    }

    if (firstLoad) {
      if (userdetails !== null) {
        dispatch(loggedin(userdetails));
      }
      fetch(`${process.env.PUBLIC_URL}/app.json`)
        .then((r) => r.json())
        .then((data) => {
          localStorage.setItem(
            "settings",
            CryptoAES.encrypt(
              JSON.stringify({
                serverUrl: data.serverurl,
                facilityName: data.facilityname,
              }),
              "cbs211&213&215"
            )
          );

          setserverUrl(data.serverurl);

          dispatch(
            setSettings({
              serverUrl: data.serverurl,
              facilityName: data.facilityname,
            })
          );
        });
      setfirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogOut = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        dispatch(loggedout());
        props.history.push("/login");

        var userdetails = localStorage.getItem("userdetails");
        var udetails = CryptoAES.decrypt(
          userdetails.toString(),
          "cbs211&213&215"
        );
        var u = JSON.parse(udetails.toString(CryptoENC));

        localStorage.removeItem("userdetails");
        localStorage.removeItem("token_id");
        axios
          .post(serverUrl + "/api/logout", { userid: u.userid })
          .then((res) => {
            if (res.data.Status === 1) {
            } else {
            }
          })
          .catch((error) => {
            Swal.fire({
              title: facilityName,
              text: "Error" + error,
              type: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          })
          .finally(function () {});
      }
    });
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleUtil = (r) => {
    props.history.push(r);
  };
  const UserLogInLogOut = (props) => {
    if (isLogged) {
      return (
        <div>
          <ListItem button key={"username"} onClick={handleClickUser}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faUser} />
            </ListItemIcon>
            <ListItemText primary={username} />
          </ListItem>

          <Collapse in={openUsers} unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={handleUserProfile}
              >
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={handleChangePassword}
              >
                <ListItemText primary="Change Password" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={handleLogOut}
              >
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Collapse>
        </div>
      );
    } else {
      return (
        <ListItem button key={"login"} onClick={handleLogIn}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUser} />
          </ListItemIcon>
          <ListItemText primary={"Log-In"} />
        </ListItem>
      );
    }
  };

  const handleUserProfile = () => {
    props.history.push({
      pathname: "/userprofile",
      state: {
        id: userid,
        requirePassword: true,
        returnUrl: "/",
        enableAdmin: false,
      },
    });
  };

  const handleCloseUser = () => {
    setAnchorEl(null);
  };
  const handleLogIn = () => {
    props.history.push("/login");
  };
  const handleChangePassword = () => {
    handleCloseUser();
    props.history.push({
      pathname: "/changepassword",
      state: {
        id: userid,
        requirePassword: true,
        returnUrl: "/",
      },
    });
  };

  const handleClickUser = () => {
    setOpenUsers(!openUsers);
  };

  const handleClickFile = () => {
    setOpenFile(!openFile);
  };

  const handleClickSettings = () => {
    setopenSettings(!openSettings);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            EMR for {facilityName}
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <UserLogInLogOut />
        <Divider />
        <List>
          <ListItem button key={"home"} onClick={(text) => handleUtil("/")}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faHome} />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>

          <ListItem
            button
            key={"patients"}
            onClick={(text) => handleUtil("/patients")}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faLaptopMedical} />
            </ListItemIcon>
            <ListItemText primary={"Patients"} />
          </ListItem>

          <ListItem button key={"settings"} onClick={handleClickSettings}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faFileMedical} />
            </ListItemIcon>
            <ListItemText primary={"Transaction"} />
          </ListItem>
          <Collapse in={openSettings} unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/classification")}
              >
                <ListItemText primary="Classification" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/qualitymanagements")}
              >
                <ListItemText primary="Quality Management" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/bedcapacity")}
              >
                <ListItemText primary="Bed Capacity" />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/testing")}
              >
                <ListItemText primary="Testing" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/expenses")}
              >
                <ListItemText primary="Expenses" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/revenues")}
              >
                <ListItemText primary="Revenues" />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/staffing")}
              >
                <ListItemText primary="Staffing" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/emrsubmission")}
              >
                <ListItemText primary="Emr Submission" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button key={"file"} onClick={handleClickFile}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faFolder} />
            </ListItemIcon>
            <ListItemText primary={"File"} />
          </ListItem>
          <Collapse in={openFile} unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleUtil("/users")}
              >
                <ListItemText primary="Users" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>

      <main className={classes.content}>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {loading && (
            <Loader type="ThreeDots" color="#76448A" height={80} width={80} />
          )}
        </div>
        <div className={classes.toolbar1} />

        <Routes isLogged={isLogged} />
      </main>
    </div>
  );
};

export default withRouter(App);

import React from "react";
import { withRouter } from "react-router-dom";

const Test2 = (props) => {
  console.log(props);
  return (
    <div>
      <h1>Hello {props.value}</h1>
      <h1>Hello {props.valuex}</h1>
    </div>
  );
};

export default withRouter(Test2);

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ReactDataGrid from "react-data-grid";
import "react-data-grid-multiline-header/style.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { notloading } from "../../actions";
import Swal from "sweetalert2";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";

import { Typography, MenuItem, Select } from "@material-ui/core";

const Staffing = (props) => {
  const dispatch = useDispatch();
  const [reportingYears, setreportingYears] = useState([]);
  const [firstLoad, setfirstLoad] = useState(true);
  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));

  var userdetails = localStorage.getItem("userdetails");
  let udetails = "";
  let u = null;
  let userFullname = "";
  let facilitycode = "";
  let username = "";
  if (userdetails !== null) {
    udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
    u = JSON.parse(udetails.toString(CryptoENC));
    userFullname = u.userFullname;
    facilitycode = u.facilitycode;
    username = u.username;
  }

  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;
  const [alreadyloaded, setalreadyloaded] = useState(false);
  useEffect(() => {
    if (firstLoad) {
      handlegetTableRecords("tblreportingyear");

      setfirstLoad(false);
    }
  }, [firstLoad, alreadyloaded]);

  const handlegetTableRecords = (tblName) => {
    let q = "";

    q = "select * from " + tblName + " order by id";

    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: q,
        },
      })
      .then((res) => {
        if (tblName === "tblreportingyear") {
          setreportingYears(res.data);
        }
        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };
  const columns = [
    { key: "Id", name: "Id", width: 0 },
    {
      key: "PosDesc",
      name: "Profession/ Position/ Designation",
      editable: false,
      width: 300,
    },
    {
      key: "SpecialtyBoardCertified",
      name: "Specialty Board Certified",
      editable: true,
    },
    {
      key: "FulltimePermanentStaff",
      name: "Number of permanent full time staff",
      editable: true,
    },
    {
      key: "FulltimeContractualStaff",
      name: "Number of contractual full time staff",
      editable: true,
    },
    {
      key: "PermanentPartimeStaff",
      name: "Number of permanent part time staff",
      editable: true,
    },
    {
      key: "ContractualPartimeStaff",
      name: "Number of contractual part time staff",
      editable: true,
    },
    {
      key: "ActiveRotating",
      name: "Active Rotating or Visiting/ Affiliate",
      editable: true,
    },
    {
      key: "Outsourced",
      name: "Outsourced",
      editable: true,
    },
  ];

  const [rows, setrows] = useState([]);
  const [state, setState] = React.useState({
    ReportingYear: "",
  });
  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });

    setalreadyloaded(false);
  };
  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const r = rows.slice();
    for (let i = fromRow; i <= toRow; i++) {
      r[i] = { ...r[i], ...updated };
    }
    /*    console.log("handleGridRowsUpdated", JSON.stringify(updated));

    console.log(rows[fromRow]); */

    const valuestosave = {
      ...updated,
      id: rows[fromRow].Id,
    };
    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(serverUrl + "/api/savestaffing", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          setrows(r);
          dispatch(notloading());
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
      })
      .catch((error) => {
        dispatch(notloading());

        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  };

  function handleReportingYearChange() {
    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(serverUrl + "/api/getstaffing", {
        params: {
          reportingyear: state.ReportingYear,
          facilitycode: facilitycode,
          username: userFullname,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setrows(res.data);
          setalreadyloaded(true);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        /*         Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false
        }); */
      });
  }
  useEffect(() => {
    handleReportingYearChange();
  }, [state.ReportingYear]);

  return (
    <div className="react-grid-multiline-header" style={{ marginTop: 15 }}>
      <Typography variant="h6" component="h6" align="center">
        Staffing
      </Typography>

      {"Reporting Year: "}
      <Select
        labelid="Reporting Years"
        name="ReportingYear"
        id="ReportingYear"
        value={state.ReportingYear}
        onChange={handleChange("ReportingYear")}
        // onChange={handleReportingYearChange(state.ReportingYear)}
      >
        {reportingYears.map((option) => (
          <MenuItem key={option.Id} value={option.ReportingYear}>
            {option.ReportingYear}
          </MenuItem>
        ))}
      </Select>

      <ReactDataGrid
        columns={columns}
        rowGetter={(i) => rows[i]}
        rowsCount={rows.length}
        minHeight={550}
        onGridRowsUpdated={onGridRowsUpdated}
        enableCellSelect={true}
        headerRowHeight={100}
      />
    </div>
  );
};

export default withRouter(Staffing);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Swal from "sweetalert2";

import axios from "axios";

import MUIDataTable from "mui-datatables";
import { faMedkit } from "@fortawesome/free-solid-svg-icons";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import {
  Button,
  Grid,
  Paper,
  Typography,
  MenuItem,
  InputBase,
  IconButton,
  Select,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  dense: {
    marginTop: 19,
  },
  paper1: {
    padding: "2px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginr: {
    marginRight: 4,
  },
}));
const SurgicalOperations = (props) => {
  const dispatch = useDispatch();
  const CaseId = props.CaseId;
  const PatientName = props.PatientName;
  const [rowsSurgical, setRowsSurgical] = useState([]);
  const [rowsSurgicalAdded, setRowsSurgicalAdded] = useState([]);

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  const userFullname = u.userFullname;
  const [firstLoad, setfirstLoad] = useState(true);
  const [rowsSelected, setrowsSelected] = useState([]);
  const [rowsSelectedAdded, setrowsSelectedAdded] = useState([]);
  const [state, setState] = React.useState({
    TypeOfOperation: "X",
    SurgicalOp: "",
  });

  useEffect(() => {
    if (firstLoad) {
      if (CaseId !== "") {
        handleGetSurgicalOperationsPerCase();
      }
      setfirstLoad(false);
    }
  }, [firstLoad]);

  const classes = useStyles();
  const typeofoperation = [
    {
      value: "X",
      label: "Please Select",
    },
    {
      value: "MINOR",
      label: "MINOR",
    },
    {
      value: "MAJOR",
      label: "MAJOR",
    },
  ];
  const [values] = React.useState({
    SurgicalOp: "",
  });

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const columnsSurgicalOperations = [
    {
      name: "Description",
      label: "Description",
      options: {
        display: "true",
        filter: false,
      },
    },
    {
      name: "Code",
      label: "Code",
      options: {
        display: "true",
        filter: false,
      },
    },
  ];

  const columnsSurgicalOperationsAdded = [
    {
      name: "Description",
      label: "Description",
      options: {
        display: "true",
        filter: false,
      },
    },
    {
      name: "Code",
      label: "Code",
      options: {
        display: "true",
        filter: false,
      },
    },
    {
      name: "Id",
      label: "Id",
      options: {
        display: "false",
        filter: false,
      },
    },
    {
      name: "TypeOfOperation",
      label: "Type Of Operation",
      options: {
        display: "true",
        filter: false,
      },
    },
  ];

  const optionsSurgicalOperations = {
    filterType: "dropdown",
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    selectableRows: "single",
    selectableRowsOnClick: true,
    serverSide: false,
    pagination: false,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
      customToolbarSelect(selectedRows, displayData, setSelectedRows),
    responsive: "scrollMaxHeight",
    rowsPerPage: 100,
    rowsSelected: rowsSelected,
    onRowsSelect: (rowsSelected, allRows) => {
      setrowsSelected(allRows.map((row) => row.dataIndex));
    },
    expandableRows: false,
  };

  const optionsSurgicalOperationsAdded = {
    filterType: "dropdown",
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    selectableRows: "single",
    selectableRowsOnClick: true,
    serverSide: false,
    pagination: false,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
      customToolbarSelectAdded(selectedRows, displayData, setSelectedRows),
    responsive: "scrollMaxHeight",
    rowsPerPage: 100,
    rowsSelected: rowsSelectedAdded,
    onRowsSelect: (rowsSelected, allRows) => {
      setrowsSelectedAdded(allRows.map((row) => row.dataIndex));
    },
    expandableRows: false,
  };

  const handleAddSurgicalProcedure = (code) => {
    if (state.TypeOfOperation === "X" || state.TypeOfOperation === "") {
      Swal.fire({
        title: facilityname,
        text: "Please select type of operation!",
        type: "error",
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
      return;
    }

    dispatch(setloading());

    const valuestosave = {
      code: code,
      userFullname: userFullname,
      CaseId: CaseId,
      TypeOfOperation: state.TypeOfOperation,
    };

    axios
      .post(serverUrl + "/api/savesurgicalprocedure", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              setState({
                ...state,
                TypeOfOperation: "X",
              });
              handleGetSurgicalOperationsPerCase();
              dispatch(notloading());
              //  props.history.push("/patients");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
      })
      .catch((error) => {
        dispatch(notloading());

        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  };

  const customToolbarSelect = (selectedRows, displayData, setSelectedRows) => {
    const newSelectedRow = selectedRows.data[selectedRows.data.length - 1];
    const selectedData = displayData[newSelectedRow.index];

    let proc = "";
    if (selectedData != null) {
      proc = selectedData.data[1];
    }

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.margin}
          onClick={() => handleAddSurgicalProcedure(proc)}
        >
          <FontAwesomeIcon icon={faMedkit} className={classes.marginr} />
          Add to Patient's Surg. Proc.
        </Button>
      </div>
    );
  };

  const customToolbarSelectAdded = (
    selectedRows,
    displayData,
    setSelectedRows
  ) => {
    const newSelectedRow = selectedRows.data[selectedRows.data.length - 1];
    const selectedDataAdded = displayData[newSelectedRow.index];
    let proc = "";
    if (selectedDataAdded != null) {
      proc = selectedDataAdded.data[2];
    }

    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.margin}
          onClick={() => handleDeleteOperation(proc)}
        >
          <FontAwesomeIcon icon={faTrash} className={classes.marginr} />
          Delete
        </Button>
      </div>
    );
  };

  const handleDeleteOperation = (proc) => {
    dispatch(setloading());

    const valuestosave = {
      userFullname: userFullname,
      Id: proc,
    };

    axios
      .post(serverUrl + "/api/deletesurgicaloperation", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: "Successfully Deleted!",
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              handleGetSurgicalOperationsPerCase();
              dispatch(notloading());
              //  props.history.push("/patients");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: "Warning",
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
      })
      .catch((error) => {
        dispatch(notloading());

        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  };

  const handleSearchSurgicalOperations = () => {
    console.log(values.SurgicalOp);

    dispatch(setloading());

    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    axios
      .get(serverUrl + "/api/searchsurgicaloperations", {
        params: {
          searchStr: state.SurgicalOp,
        },
      })
      .then((res) => {
        setRowsSurgical(res.data.lst);

        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  const handleGetSurgicalOperationsPerCase = () => {
    dispatch(setloading());
    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .get(serverUrl + "/api/getsurgicaloperationspercase", {
        params: {
          CaseId: CaseId,
        },
      })
      .then((res) => {
        setRowsSurgicalAdded(res.data.lst);

        dispatch(notloading());
      })

      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h6" component="h6" align="center">
          Surgical Operation(s) for patient {PatientName}
        </Typography>
        <div className={classes.wrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.root}>
                {/*   <Typography variant="h5" component="h3" align="center">
                    Surgical Operations
                  </Typography> */}

                <Paper className={classes.paper1}>
                  <InputBase
                    className={classes.input}
                    placeholder="Search Surgical Operation"
                    inputProps={{ "aria-label": "search surgical operation" }}
                    style={{ width: 300 }}
                    id="SurgicalOp"
                    name="SurgicalOp"
                    value={state.SurgicalOp}
                    onChange={handleChange("SurgicalOp")}
                  />
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={() => handleSearchSurgicalOperations()}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
                {"Type of Operation "}
                <Select
                  labelid="Type Of Operation"
                  name="TypeOfOperation"
                  id="TypeOfOperation"
                  value={state.TypeOfOperation}
                  onChange={handleChange("TypeOfOperation")}
                >
                  {typeofoperation.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>

                <MUIDataTable
                  className={classes.margin}
                  title={"List of Surgical Operations"}
                  data={rowsSurgical}
                  columns={columnsSurgicalOperations}
                  options={optionsSurgicalOperations}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className={classes.root}>
                {/*      <Typography variant="h5" component="h3" align="center">
                    Added Surgical Operations
                  </Typography> */}

                <MUIDataTable
                  className={classes.margin}
                  title={"Added Surgical Operations"}
                  data={rowsSurgicalAdded}
                  columns={columnsSurgicalOperationsAdded}
                  options={optionsSurgicalOperationsAdded}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default withRouter(SurgicalOperations);

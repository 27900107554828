import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Users from "./file/Users";
import UserProfile from "./file/UserProfile";
import ChangePassword from "./file/ChangePassword";
import Home from "./Home";
import LogIn from "./LogIn";
import Patients from "./transaction/Patients";
import AdmissionDetails from "./transaction/AdmissionDetails";
import ConditionOnDischarge from "./transaction/ConditionOnDischarge";
import SurgicalOperations from "./transaction/SurgicalOperations";
import Testing from "./transaction/Testing";
import Expenses from "./transaction/Expenses";
import Revenues from "./transaction/Revenues";
import Diagnosis from "./transaction/Diagnosis";
import Staffing from "./transaction/Staffing";
import Classification from "./file/Classification";
import QualityManagement from "./file/QualityManagement";
import QualityManagements from "./file/QualityManagements";
import BedCapacity from "./file/BedCapacity";
import EmrSubmission from "./transaction/EmrSubmission";
import SubmitReport2 from "./transaction/SubmitReport2";

import Test from "./transaction/Test";
import axios from "axios";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";

let retval = false;
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )
    }
  />
);

const AuthRouteComlogik = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthComlogik() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/home",
          }}
        />
      )
    }
  />
);

const checkAuthComlogik = (props) => {
  let isLoggedIn = false;
  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var u = JSON.parse(udetails.toString(CryptoENC));

  if (userdetails !== null) {
    isLoggedIn = true;
  }
  if (userdetails === null) {
    isLoggedIn = false;
  } else {
    if (u.username === "COMLOGIK" || u.username === "ALLAN") {
      isLoggedIn = true;
    } else {
      isLoggedIn = false;
    }
  }

  return isLoggedIn;
};

const checkAuth = (props) => {
  var u = localStorage.getItem("userdetails");
  let isLoggedIn = false;
  if (u !== null) {
    isLoggedIn = true;
    checkLogInKey();
  }

  var u1 = localStorage.getItem("userdetails");
  if (u1 === null) {
    isLoggedIn = false;
  }
  return isLoggedIn;
};

const checkLogInKey = () => {
  var userdetails = localStorage.getItem("userdetails");
  var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
  var usr = JSON.parse(udetails.toString(CryptoENC));

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;

  const Id = usr.token;
  const userid = usr.userid;

  axios
    .get(serverUrl + "/api/checkloginkey/", {
      params: {
        Id: Id,
      },
    })
    .then((res) => {
      if (res.data.Status === 1) {
        retval = true;
      } else {
        axios
          .post(serverUrl + "/api/logout", { userid: userid })
          .then((res) => {
            localStorage.removeItem("userdetails");
          })
          .catch((error) => {})
          .finally(function () {});
        retval = false;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

const Routes = (props) => {
  const { isLogged } = props;
  sessionStorage.setItem("isLogged", isLogged);

  return (
    <Switch>
      <Route exact strict path="/" component={Home} />
      <Route exact path="/login" component={LogIn} />

      <AuthRouteComlogik
        exact
        strict
        path="/users"
        component={Users}
        render={() => <Users />}
      />
      <AuthRoute
        exact
        strict
        path="/userprofile"
        component={UserProfile}
        render={() => <UserProfile />}
      />

      <AuthRoute
        exact
        strict
        path="/changepassword"
        component={ChangePassword}
        render={() => <ChangePassword />}
      />

      <AuthRoute
        exact
        strict
        path="/submitreport2"
        component={SubmitReport2}
        render={() => <SubmitReport2 />}
      />

      <AuthRoute
        exact
        strict
        path="/patients"
        component={Patients}
        render={() => <Patients />}
      />

      <AuthRoute
        exact
        strict
        path="/admissiondetails"
        component={AdmissionDetails}
        render={() => <AdmissionDetails />}
      />
      <AuthRoute
        exact
        strict
        path="/conditionondischarge"
        component={ConditionOnDischarge}
        render={() => <ConditionOnDischarge />}
      />

      <AuthRoute
        exact
        strict
        path="/surgicaloperations"
        component={SurgicalOperations}
        render={() => <SurgicalOperations />}
      />

      <AuthRoute
        exact
        strict
        path="/diagnosis"
        component={Diagnosis}
        render={() => <Diagnosis />}
      />

      <AuthRoute
        exact
        strict
        path="/classification"
        component={Classification}
        render={() => <Classification />}
      />

      <AuthRoute
        exact
        strict
        path="/qualitymanagement"
        component={QualityManagement}
        render={() => <QualityManagement />}
      />

      <AuthRoute
        exact
        strict
        path="/qualitymanagements"
        component={QualityManagements}
        render={() => <QualityManagements />}
      />
      <AuthRoute
        exact
        strict
        path="/bedcapacity"
        component={BedCapacity}
        render={() => <BedCapacity />}
      />
      <AuthRoute
        exact
        strict
        path="/testing"
        component={Testing}
        render={() => <Testing />}
      />
      <AuthRoute
        exact
        strict
        path="/test"
        component={Test}
        render={() => <Test />}
      />

      <AuthRoute
        exact
        strict
        path="/expenses"
        component={Expenses}
        render={() => <Expenses />}
      />

      <AuthRoute
        exact
        strict
        path="/revenues"
        component={Revenues}
        render={() => <Revenues />}
      />

      <AuthRoute
        exact
        strict
        path="/staffing"
        component={Staffing}
        render={() => <Staffing />}
      />
      <AuthRoute
        exact
        strict
        path="/emrsubmission"
        component={EmrSubmission}
        render={() => <EmrSubmission />}
      />
    </Switch>
  );
};

export default Routes;

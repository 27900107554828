/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setloading, notloading } from "../../actions";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Swal from "sweetalert2";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";

import {
  Button,
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  MenuItem,
} from "@material-ui/core";
import { TextField, CheckboxWithLabel, Checkbox } from "formik-material-ui";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
const AdmissionDetails = (props) => {
  const dispatch = useDispatch();

  var settingsE = localStorage.getItem("settings");
  var settings = CryptoAES.decrypt(settingsE.toString(), "cbs211&213&215");
  var s = JSON.parse(settings.toString(CryptoENC));
  const serverUrl = s.serverUrl;
  const facilityname = s.facilityName;

  const [details, setdetails] = useState([]);
  const [patientdetails, setpatientdetails] = useState([]);
  const Id = props.location.state.Id;
  const PatientId = props.location.state.PatientId;

  const [firstLoad, setfirstLoad] = useState(true);

  const [deliveries, setdeliveries] = useState([]);
  const [servicetypeOpd, setservicetypeOpd] = useState([]);
  const isEdit = Id === "" ? false : true;

  useEffect(() => {
    if (firstLoad) {
      handlegetTableRecords("tbldeliveries");
      handlegetTableRecords("tblservicetypeop");
      if (Id !== "") {
        handleAdmissionDetails();
      }
      if (PatientId !== "") {
        handleGetPatientInformation();
      }
      setfirstLoad(false);
    }
  }, [firstLoad]);

  const gender = [
    {
      value: "0",
      label: "Please Select",
    },
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
  ];

  const servicetype = [
    {
      value: "X",
      label: "Please Select",
    },
    {
      value: "1",
      label: "Medicine",
    },
    {
      value: "2",
      label: "Obstetrics",
    },
    {
      value: "3",
      label: "Gynecology",
    },
    {
      value: "4",
      label: "Pediatrics",
    },
    {
      value: "5",
      label: "Pedia",
    },
    {
      value: "6",
      label: "Adult",
    },
    {
      value: "7",
      label: "Others",
    },
    {
      value: "8",
      label: "Pathologic",
    },
    {
      value: "9",
      label: "Non-Pathologic/Well-baby",
    },
  ];

  const typeofpatient = [
    {
      value: "X",
      label: "Please Select",
    },
    {
      value: "IP",
      label: "In-Patient",
    },
    {
      value: "OP",
      label: "Out-Patient",
    },
    {
      value: "ER",
      label: "E.R Patient",
    },
  ];

  const typeofaccomodation = [
    {
      value: "X",
      label: "Please Select",
    },
    {
      value: "NPHICPAY",
      label: "Non-Philhealth Pay",
    },
    {
      value: "NPHICPAYHMO",
      label: "Non-Philhealth Pay with HMO",
    },
    {
      value: "NPHICSERVICE",
      label: "Non-Philhealth Service/Charity",
    },
    {
      value: "NPHICSERVICEHMO",
      label: "Non-Philhealth Service/Charity with HMO",
    },
    {
      value: "PHICPAY",
      label: "Philhealth Pay",
    },
    {
      value: "PHICPAYHMO",
      label: "Philhealth Pay with HMO",
    },
    {
      value: "PHICSERVICE",
      label: "Philhealth Service/Charity",
    },
    {
      value: "HMO",
      label: "HMO",
    },
    {
      value: "OWWA",
      label: "OWWA",
    },
  ];

  const handlegetTableRecords = (tblName) => {
    let q = "";

    q = "select * from " + tblName + " order by id";

    axios
      .get(serverUrl + "/api/gettablerecords", {
        params: {
          q: q,
        },
      })
      .then((res) => {
        if (tblName === "tbldeliveries") {
          setdeliveries(res.data);
        } else if (tblName === "tblservicetypeop") {
          setservicetypeOpd(res.data);
        }

        dispatch(notloading());
      })
      .catch((error) => {
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      });
  };

  const classes = useStyles();
  function handleInitialValues() {
    return {
      PatientNo: PatientId !== "" ? patientdetails.PatientNo : "",
      CaseNo: Id !== "" ? details.CaseNo : "",
      LastName: PatientId !== "" ? patientdetails.LastName : "",
      FirstName: PatientId !== "" ? patientdetails.FirstName : "",
      MiddleName: PatientId !== "" ? patientdetails.MiddleName : "",
      Suffix: PatientId !== "" ? patientdetails.Suffix : "",
      Gender: patientdetails.Gender !== undefined ? patientdetails.Gender : "",
      Address: PatientId !== "" ? patientdetails.Address : "",
      ContactNos: PatientId !== "" ? patientdetails.ContactNos : "",
      BirthDate:
        PatientId !== ""
          ? moment(new Date(patientdetails.BirthDate)).format("YYYY-MM-DD")
          : "",
      DateAdmitted:
        Id !== ""
          ? moment(new Date(details.DateAdmitted)).format("YYYY-MM-DD")
          : "",
      DateDischarged:
        Id !== ""
          ? moment(new Date(details.DateDischarged)).format("YYYY-MM-DD")
          : "",
      Active: true,
      FkCodetblTypeOfPatient:
        details.FkCodetblTypeOfPatient !== undefined
          ? details.FkCodetblTypeOfPatient
          : "X",
      FkIdtblServiceType:
        details.FkIdtblServiceType !== undefined
          ? details.FkIdtblServiceType
          : "X",
      FkCodetblTypeOfAccomodation:
        details.FkCodetblTypeOfAccomodation !== undefined
          ? details.FkCodetblTypeOfAccomodation
          : "X",
      CaseId: Id,
      PatientId: PatientId,
      TransferFrom: isEdit ? details.TransferFrom : false,
      TransferFromHospital: Id !== "" ? details.TransferFromHospital : "",
      TransferTo: Id !== "" ? details.TransferTo : false,
      TransferToHospital: Id !== "" ? details.TransferToHospital : "",
      GaveBirth: Id !== "" ? details.GaveBirth : false,
      DeliveryType:
        details.DeliveryType !== undefined
          ? details.DeliveryType === ""
            ? "X"
            : details.DeliveryType
          : "X",
      Religion: Id !== "" ? details.Religion : "",
      FkCodetblServiceTypeOp:
        details.FkCodetblServiceTypeOp !== undefined
          ? details.FkCodetblServiceTypeOp === ""
            ? "X"
            : details.FkCodetblServiceTypeOp
          : "X",
      InPatientServiceTypeOthers:
        Id !== "" ? details.InPatientServiceTypeOthers : "",
    };
  }

  const handleAdmissionDetails = () => {
    dispatch(setloading());

    axios
      .get(serverUrl + "/api/admissiondetails/", {
        params: {
          id: Id,
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          setdetails(res.data.details);
          console.log(res.data.details);
          dispatch(notloading());
        } else {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleGetPatientInformation = () => {
    dispatch(setloading());

    axios
      .get(serverUrl + "/api/getpatientinformation/", {
        params: {
          id: PatientId,
        },
      })
      .then((res) => {
        if (res.data.status === 1) {
          console.log(res.data.details);
          setpatientdetails(res.data.details);

          dispatch(notloading());
        } else {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function handleClick(x) {
    props.history.push(x);
  }

  function validateFields(values) {
    const errors: Partial<Values> = {};

    if (!values.LastName) {
      errors.LastName = "Lastname is required!";
    }

    if (!values.FirstName) {
      errors.FirstName = "Firstname is required!";
    }

    if (!values.Gender) {
      errors.Gender = "Please select a gender!";
    }

    if (values.Gender === "0") {
      errors.Gender = "Please select a gender!";
    }

    if (!values.BirthDate) {
      errors.BirthDate = "Date of birth is required!";
    }

    if (!values.DateAdmitted) {
      errors.DateAdmitted = "Date admitted is required!";
    }
    if (!values.DateDischarged) {
      errors.DateDischarged = "Date discharged is required!";
    }

    if (values.FkCodetblTypeOfPatient === "X") {
      errors.FkCodetblTypeOfPatient = "Please select type of patient";
    }

    if (values.FkCodetblTypeOfPatient === "IP") {
      if (values.FkIdtblServiceType === "X") {
        errors.FkIdtblServiceType = "Please select a service type";
      }
      if (values.FkCodetblTypeOfAccomodation === "X") {
        errors.FkCodetblTypeOfAccomodation =
          "Please select type of accomodation";
      }
    } else {
      values.FkIdtblServiceType = "X";
      values.FkCodetblTypeOfAccomodation = "X";
    }

    if (values.FkIdtblServiceType === "7") {
      if (
        values.InPatientServiceTypeOthers === "" ||
        values.InPatientServiceTypeOthers === null
      ) {
        errors.InPatientServiceTypeOthers =
          "Please specify OTHER Service Type!";
      }
    }

    if (values.FkCodetblTypeOfPatient === "OP") {
      if (
        values.FkCodetblServiceTypeOp === "" ||
        values.FkCodetblServiceTypeOp === "X"
      ) {
        errors.FkCodetblServiceTypeOp = "This field is required!s";
      }
    } else {
      values.FkCodetblServiceTypeOp = "X";
    }

    if (values.TransferFrom === true) {
      if (
        values.TransferFromHospital === "" ||
        values.TransferFromHospital === null
      ) {
        errors.TransferFromHospital = "This field is required!";
      }
    } else {
      values.TransferFromHospital = "";
    }

    if (values.TransferTo === true) {
      if (
        values.TransferToHospital === "" ||
        values.TransferToHospital === null
      ) {
        errors.TransferToHospital = "This field is required!";
      }
    } else {
      values.TransferToHospital = "";
    }

    if (values.GaveBirth === true) {
      if (values.DeliveryType === "" || values.DeliveryType === "X") {
        errors.DeliveryType = "This field is required!";
      }
    } else {
      values.DeliveryType = "X";
    }

    return errors;
  }
  function submitForm(values, { setSubmitting, resetForm }) {
    dispatch(setloading());

    var userdetails = localStorage.getItem("userdetails");
    var udetails = CryptoAES.decrypt(userdetails.toString(), "cbs211&213&215");
    var u = JSON.parse(udetails.toString(CryptoENC));

    const valuestosave = {
      ...values,
      userFullname: u.userFullname,
      username: u.username,
    };

    var serverUrl = localStorage.getItem("serverUrl");
    var token = localStorage.getItem("token_id");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios
      .post(serverUrl + "/api/saveadmissiondetails", valuestosave)
      .then((res) => {
        if (res.data.Status === 1) {
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              dispatch(notloading());
              props.history.push("/patients");
            }
          });
        } else {
          dispatch(notloading());
          Swal.fire({
            title: facilityname,
            text: res.data.Message,
            type: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(notloading());
        setSubmitting(false);
        Swal.fire({
          title: facilityname,
          text: "Error" + error,
          type: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      })
      .finally(function () {});
  }
  return (
    <div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" href="#" onClick={() => handleClick("/home")}>
          Home
        </Link>
        <Link color="inherit" href="#" onClick={() => handleClick("/patients")}>
          Patients
        </Link>
      </Breadcrumbs>

      <Formik
        enableReinitialize
        initialValues={handleInitialValues()}
        validate={(values) => validateFields(values)}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          submitForm(values, { setSubmitting, resetForm })
        }
        render={({
          submitForm,
          isSubmitting,
          values,
          setFieldValue,
          touched,
        }) => (
          <Form>
            {" "}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.root}>
                  <Typography variant="h6" component="h6" align="center">
                    Patient Information
                  </Typography>
                  <Field
                    name="PatientNo"
                    type="text"
                    label="Patient No."
                    component={TextField}
                    //  variant="outlined"
                    margin="dense"
                    fullWidth
                    autoFocus
                    disabled={Id !== ""}
                    InputLabelProps={{
                      shrink: values.PatientNo !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Lastname"
                    name="LastName"
                    placeholder="Lastname"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.LastName !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Firstname"
                    name="FirstName"
                    placeholder="Firstname"
                    component={TextField}
                    //  variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.FirstName !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Middlename"
                    name="MiddleName"
                    placeholder="Middlename"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.MiddleName !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Suffix"
                    name="Suffix"
                    placeholder="Suffix"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Suffix !== "",
                    }}
                  />

                  <Field
                    type="text"
                    label="Gender"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    name="Gender"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {gender.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="text"
                    label="Religion"
                    name="Religion"
                    placeholder="Religion"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Religion !== "",
                    }}
                  />
                  <Field
                    type="date"
                    label="Date of birth"
                    name="BirthDate"
                    component={TextField}
                    // variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    fullWidth
                  />

                  <Field
                    type="text"
                    label="Address"
                    name="Address"
                    placeholder="Address"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.Address !== "",
                    }}
                  />

                  <Field
                    type="text"
                    label="Contact Nos."
                    name="ContactNos"
                    placeholder="Contact Nos."
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.ContactNos !== "",
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.root}>
                  <Typography variant="h5" component="h3" align="center">
                    Case Information
                  </Typography>
                  <Field
                    name="CaseNo"
                    type="text"
                    label="Case No."
                    component={TextField}
                    //  variant="outlined"
                    margin="dense"
                    fullWidth
                    autoFocus
                    InputLabelProps={{
                      shrink: values.CaseNo !== "",
                    }}
                  />
                  <Field
                    type="date"
                    label="Date Admitted"
                    name="DateAdmitted"
                    component={TextField}
                    // variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    type="date"
                    label="Date Discharged"
                    name="DateDischarged"
                    component={TextField}
                    // variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    type="text"
                    label="Type of patient"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    name="FkCodetblTypeOfPatient"
                    value={details.FkCodetblTypeOfPatient}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {typeofpatient.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="text"
                    label="Service Type(In-Patient)"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    name="FkIdtblServiceType"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {servicetype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="text"
                    label="Service Type(Others)"
                    name="InPatientServiceTypeOthers"
                    placeholder="Service Type(Others)"
                    component={TextField}
                    // variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: values.InPatientServiceTypeOthers !== "",
                    }}
                  />
                  <Field
                    type="text"
                    label="Type of accomodation"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    name="FkCodetblTypeOfAccomodation"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {typeofaccomodation.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    type="text"
                    label="Service Type(Out-Patient)"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    name="FkCodetblServiceTypeOp"
                    disabled={values.FkCodetblTypeOfPatient === "IP"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {servicetypeOpd.map((option) => (
                      <MenuItem key={option.Code} value={option.Code}>
                        {option.Description}
                      </MenuItem>
                    ))}
                  </Field>
                  Patient Gave Birth{" "}
                  <Field
                    label="Patient Gave Birth"
                    name="GaveBirth"
                    id="GaveBirth"
                    color="primary"
                    component={Checkbox}
                    type="checkbox"
                    disabled={values.Gender !== "F"}
                    value={values.GaveBirth != null ? values.GaveBirth : false}
                  />
                  <Field
                    type="text"
                    label="Delivery Type"
                    select
                    margin="dense"
                    component={TextField}
                    fullWidth
                    name="DeliveryType"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {deliveries.map((option) => (
                      <MenuItem key={option.Code} value={option.Code}>
                        {option.Description}
                      </MenuItem>
                    ))}
                  </Field>
                  <div>
                    {"Patient Transfer to this Facility from another facility?"}
                    <Field
                      label="Patient Transfer to this Facility from another facility?"
                      name="TransferFrom"
                      id="TransferFrom"
                      color="primary"
                      component={Checkbox}
                      type="checkbox"
                      value={
                        values.TransferFrom != null
                          ? values.TransferFrom
                          : false
                      }
                    />
                    <Field
                      name="TransferFromHospital"
                      type="text"
                      label="Transfer from facility name"
                      component={TextField}
                      //  variant="outlined"
                      margin="dense"
                      fullWidth
                      autoFocus
                      values={values.TransferFromHospital}
                      disabled={values.TransferFrom === false}
                      InputLabelProps={{
                        shrink: values.TransferFromHospital !== "",
                      }}
                    />
                  </div>
                  <div>
                    Patient Transfer from this Facility to another facility?{" "}
                    <Field
                      label="Patient Transfer from this Facility to another facility?"
                      name="TransferTo"
                      id="TransferTo"
                      color="primary"
                      component={Checkbox}
                      type="checkbox"
                      value={
                        values.TransferTo != null ? values.TransferTo : false
                      }
                    />
                    <Field
                      name="TransferToHospital"
                      type="text"
                      label="Transfer to other facility name"
                      component={TextField}
                      //  variant="outlined"
                      margin="dense"
                      fullWidth
                      autoFocus
                      values={values.TransferToHospital}
                      disabled={values.TransferTo === false}
                      InputLabelProps={{
                        shrink: values.TransferToHospital !== "",
                      }}
                    />
                  </div>
                  Delete{" "}
                  <Field
                    label="Delete"
                    name="Delete"
                    id="Delete"
                    color="primary"
                    component={CheckboxWithLabel}
                  />
                  <div className={classes.wrapper}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        <SendIcon className={classes.leftIcon} />
                        Submit
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    </div>
  );
};

export default withRouter(AdmissionDetails);
